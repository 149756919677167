<template>
    <div class="text-skin-base w-full">
                <div class="md:flex  justify-end md:justify-start w-full">                  
                    <div class="my-auto p-2 md:pl-10 w-full md:w-1/5">
                        <button @click="registrar_demo()" class="w-full font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-4 mt-1 m-1">
                            Crear Demo
                        </button>                          
                    </div>
<!--                            @change="verificar_frecuencia()"                        
-->        
                </div>


        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogDemo" :value="true" @input="dialogDemo = false"                   
            >
            <v-card>
              <v-container>
                <div>
                    <DemoHeader :titulo="titulo" /> 
                </div>

                <div  class="md:p-2 flex justify-between text-skin-base">
                    <div class="p-2 text-sm md:text-lg">                        
                        <span>Demo clínica: </span><span><strong>{{ fields_demo.group_demo.tx_historia.tx_historia }}</strong>  </span>                                                
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Paciente: <span><strong>{{ fields_paciente.tx_apellidos.tx_apellidos}} {{ fields_paciente.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de apertura: <span><strong>{{ fields_demo.group_demo.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Apeturada por: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                

                <div class="w-full mx-auto my-auto">
                    <DemoActualizar titulo="Registrar historia" @guardar_demo="return_demo" :send_paciente="send_paciente" :send_motivo="send_motivo"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import { READ_PACIENTE } from '@/store/actions.type'
//import { SET_ERROR } from '@/store/mutations.type'
import DemoActualizar from "@/components/demo/DemoActualizar.vue";
import DemoHeader from "@/components/comunes/HeaderModulo.vue";

export default {
    name: 'DemoOpciones',
    data() {
        return {
            dialogDemo: false,
            buscarPaciente: false, 
            send_paciente: {},
            send_motivo: {},
            datos_demo: [],
            isLoading: false,
            json_paciente: {"tx_telefono_celular":"(272) 772-7272","tx_nombres":"Hector","bo_posee_cedula":1,"tx_historia":"0703202200:55-259C3","tx_direccion":"Caracas","tx_instagram":"@instagram","nu_sexo":2,"tx_datos_representante":null,"tx_facebook":"@facebook","json_municipio_co":"{\"tx_cod_municipio\":\"01\",\"tx_desc_municipio\":\"Bolivariano Libertador\",\"tx_cod_entidad\":\"01\"}","fe_nacimiento":"2002-02-28","tx_nombre_completo":"Hector Ortiz(10782175)","tx_telefono_local":"(272) 727-2727","json_parroquia_co":"{\"tx_cod_parroquia\":\"01\",\"tx_desc_parroquia\":\"Altagracia\"}","nu_id_demo":90,"tx_correo":"hortiz@gmail.com","json_estado_co":"{\"tx_cod_entidad\":\"01\",\"tx_desc_entidad\":\"Distrito Capital\",\"Description\":\"Distrito Capital\"}","tx_apellidos":"Ortiz","tx_documento_identidad":"10782175","tx_nacionalidad":"V","tx_twitter":"@twitter","bo_es_menor":0}
        }
    },
    props: {
        titulo: String

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
    
        registrar_demo () {
            this.dialogDemo = true
            this.send_paciente.operacion = 'REGISTRAR'
            this.send_paciente.paciente = this.json_paciente
            // console.log(value) // someValue
        },
        return_demo(msg) {
            //this.buscarPersona('params')
            //var guardar = JSON.parse(msg);
            var guardar = msg;
            //this.select_demo = JSON.parse(msg);
            console.log("return_demo guardar_demo: " , guardar);

            
            this.$emit("guardar_demo", guardar);
            this.dialogDemo = false
            //this.send_demo = msg;
        },    

    },
    computed: {
        ...mapGetters(['fields_demo','fields_paciente','currentUser']),
    },
    components: {
        DemoActualizar,
        DemoHeader
    },
    mounted() {
        //this.searchPaciente()
    },     
}
</script>