 <template>
  <div>
      <div class="w-full">

        <TratamientoHeader titulo="" />    
      </div>
      <div class="w-full">
          <TratamientoOpciones @guardar_tratamiento="return_tratamiento " :send_tratamiento="send_tratamiento" />
      </div>

      <div class="w-full">
          <TratamientoListar :tratamientos="datos_tratamiento" :send_tratamiento="send_tratamiento"></TratamientoListar>
      </div>
  </div>
</template>

<script>
import TratamientoHeader from "@/components/comunes/HeaderModulo.vue";
import TratamientoOpciones from "@/components/tratamiento/TratamientoOpciones.vue";
import TratamientoListar from "@/components/tratamiento/TratamientoListar.vue";
import {  SET_ERROR } from '@/store/mutations.type'
import { READ_TRATAMIENTO } from '@/store/actions.type'

export default {
  name: 'Tratamiento',
  data () {
    return {
      titulo: 'Tratamiento',
      datos_tratamiento: [],
            send_paciente: {},

    }
  },
  props: {
      send_tratamiento: Object

  },  
  setup() {
  },  
  computed: {
  },
  components: {
    TratamientoHeader,
    TratamientoListar,
    TratamientoOpciones    
  //  ThemifyIcon    
  },  
  watch: {
      datos_tratamiento () {
          return this.datos_tratamiento
      },

  },  
  methods: {
    searchTratamiento() {
        this.$store.dispatch(READ_TRATAMIENTO , {nu_id_paciente: this.send_tratamiento.paciente.nu_id_paciente.nu_id_paciente}).then(response => {
          this.datos_tratamiento = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },       
    return_tratamiento(msg) {
        //this.buscarPersona('params')
        var guardar = msg;
        //this.select_paciente = JSON.parse(msg);
        console.log("Consulta Tratamiento: " , guardar);

        
        if (guardar.btn_guardar == 'true') {
            this.searchTratamiento()
            //this.fields.paciente = this.select_paciente.nu_id_representante
            console.log("Guarda ultima medica: " + guardar.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar.btn_guardar);
        }
        this.dialogTratamiento = false
        //this.send_paciente = msg;
    },        

  },
  mounted () {
    //console.log( 'this.send_tratamiento: ', this.send_tratamiento)
    //  this.titulo  += ' para: ' + this.send_tratamiento.paciente.tx_nombres + ' '  + this.send_tratamiento.paciente.tx_apellidos 
    //  this.searchTratamiento()

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
<style>

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
