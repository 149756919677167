<template>
    <div>
            <div class=""  v-for="(grupos, item) in send_tipos_enfermedades"  :key="item.id">
                    <div class="">
                        
                        <validation-provider
                            v-slot="{ errors }"
                            name="grupos.seleccionado"                        
                        >
                            <v-textarea
                                v-model="grupos.seleccionado"
                                auto-grow
                                class="input-group--focused text-lg"
                                :error-messages="errors"
                                :label=grupos.enfermedad
                            ></v-textarea>                           
                        </validation-provider>         
                    </div>
                    <br />
            </div>
    </div>
</template>

<script>
    export default {
        name: "TiposEnfermedades",
        props: {
            send_tipos_enfermedades: Object
        }
    }
</script>

<style lang="scss" scoped>

</style>