var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-4 m-2 text-lg text-skin-base bg-skin-fill rounded-lg"},[_c('v-text-field',{attrs:{"clearable":"","prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Buscar tratamiento"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tratamientos,"items-per-page":5,"search":_vm.search},scopedSlots:_vm._u([{key:"item.json_frecuencia_medida",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nu_frecuencia_cantidad + " " + JSON.parse(item.json_frecuencia_medida).tx_frecuencia_medida)+" ")]}},{key:"item.json_duracion_medida",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nu_duracion_cantidad + " " + JSON.parse(item.json_duracion_medida).tx_duracion_medida)+" ")]}},{key:"item.json_medicamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.json_medicamento).tx_medicamento + " (" + JSON.parse(item.json_medicamento).tx_presentacion + ")")+" ")]}},{key:"item.bo_seguimiento",fn:function(ref){
var item = ref.item;
return [(item.bo_seguimiento == 'true')?[_c('span',[_vm._v("Si")])]:[_c('span',[_vm._v("No")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.resultado_consulta(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiBookPlusMultiple)+" ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}},{key:"item.tx_nombres",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.tx_nombres))])])]}},{key:"item.tx_apellidos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.tx_apellidos))])])]}},{key:"item.json_centro_medico",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(" "+_vm._s(JSON.parse(item.json_centro_medico).tx_razon_social)+" ")])])]}},{key:"item.json_especialidad",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(" "+_vm._s(JSON.parse(item.json_especialidad).tx_especialidad)+" ")])])]}}],null,true)})],1),(_vm.dialogConsultaMedica)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","value":true},on:{"input":function($event){_vm.dialogConsultaMedica = false}}},[_c('v-card',[_c('v-container')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }