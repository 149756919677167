 <template>
  <div class="w-full p-2">
      <div>
        <DemoHeader :titulo="titulo" /> 
      </div>
      <div>
        <DemoOpciones @guardar_demo="return_historias_clinica" :titulo="titulo"/>
      </div>
      <div>
        <DemoListar :historias_clinica="datos_historias_clinica"></DemoListar>
      </div>

  </div>
</template>

<script>
import DemoHeader from "@/components/comunes/HeaderModulo.vue";
import DemoOpciones from "@/views/demo/DemoOpciones.vue";
import DemoListar from "@/views/demo/DemoListar.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { MUESTRA_PACIENTE } from '@/store/actions.type'

export default {
  name: 'Demo',
  data () {
    return {
      titulo: 'Demo',
      datos_historias_clinica: [],
      send_historias_clinica: {},

    }
  },
  setup() {
  },  
  computed: {
  },
  components: {
    DemoHeader,
    DemoOpciones,
    DemoListar
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    searchPaciente(q) {
      this.$store.dispatch(MUESTRA_PACIENTE, {q: q}).then(response => {

          this.datos_historias_clinica = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },       
    return_historias_clinica(msg) {
      this.searchPaciente({q: msg})

      //this.send_historias_clinica = msg;
    },      
    return_consulta_medica(msg) {
        //this.buscarPersona('params')
        var guardar_historias_clinica = msg;
        //this.select_historias_clinica = JSON.parse(msg);
        console.log("return_consulta_medica guardar_historias_clinica update: " , guardar_historias_clinica);

        
        if (guardar_historias_clinica.btn_guardar == 'true') {
            this.searchPaciente({})
            //this.fields.historias_clinica = this.select_historias_clinica.nu_id_representante
            console.log("Guarda ultima medica: " + guardar_historias_clinica.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_historias_clinica.btn_guardar);
        }
        this.dialogConsultaMedica = false
        //this.send_historias_clinica = msg;
    },        

  },
  mounted () {
    //this.searchPaciente({})
    /*  this.$store.dispatch(MUESTRA_PACIENTE, {}).then(response => {

          this.datos_historias_clinica = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
