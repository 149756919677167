<template>
<div class="">
    <div class="w-full p-0 md:pl-10 md:pr-10">
    <ValidationObserver ref="datos_basicos">
      <div v-if="send_consulta_medica.operacion === 'EDITAR'" class="flex justify-center items-center" @click="fields_consulta_medica.isEditing = !fields_consulta_medica.isEditing ">
          <div  v-if="fields_consulta_medica.isEditing === true" class="cursor-pointer py-2 px-6 mr-1 border-2 rounded-lg w-12 flex justify-center items-center bg-skin-button-muted">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiStopCircleOutline }}
                  </v-icon>
              </div>

              <div class="text-white">
                Cancelar edición
              </div>

          </div>  
          <div v-else   class="cursor-pointer py-2 px-6 mr-1 border-2 rounded-lg w-12 flex justify-center items-center bg-skin-button-accent">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiPencil }}
                  </v-icon>
              </div>

              <div class="text-white">
                Editar
              </div>

          </div>
      </div>
      <div>
            <validation-provider
                v-slot="{ errors }"
                name="fields_consulta_medica.json_centro_medico.json_centro_medico"
                :rules=fields_consulta_medica.json_centro_medico.caracteristicas.required
            >
                <v-autocomplete
                    :disabled="!fields_consulta_medica.isEditing"
                    v-model="fields_consulta_medica.json_centro_medico.json_centro_medico"
                    :items="listaCentrosMedicos"
                    :loading="isLoading"
                    :error-messages="errors"
                    hide-no-data
                    hide-selected
                    item-text="tx_razon_social"
                    item-value="nu_id_centro_medico"
                    :label=fields_consulta_medica.json_centro_medico.caracteristicas.label
                    :placeholder=fields_consulta_medica.json_centro_medico.caracteristicas.placeholder
                    prepend-icon=""
                    return-object
                    @change="verificar_especialidades()"                        
                ></v-autocomplete>
            </validation-provider>

      </div>
      <div>

            <validation-provider
                v-slot="{ errors }"
                name="fields_consulta_medica.json_especialidad.json_especialidad"
                rules="required"
            >
                <v-autocomplete
                    :disabled="!fields_consulta_medica.isEditing"
                    v-model="fields_consulta_medica.json_especialidad.json_especialidad"
                    :items="listaEspecialidades"
                    :loading="isLoading"
                    :error-messages="errors"
                    hide-no-data
                    hide-selected
                    item-text="tx_especialidad"
                    item-value="nu_id_especialidad"
                    :label=fields_consulta_medica.json_especialidad.caracteristicas.label
                    :placeholder=fields_consulta_medica.json_especialidad.caracteristicas.placeholder
                    prepend-icon=""
                    return-object
                ></v-autocomplete>
            </validation-provider>

        
      </div>
      <div>
            <label>{{ fields_consulta_medica.fe_consulta.caracteristicas.label }}</label>
            <validation-provider
                v-slot="{ errors }"
                name="fields_consulta_medica.fe_consulta.fe_consulta"
                rules="required"
            >
                <v-row class="mt-2 ml-2">
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="fields_consulta_medica.fe_consulta.fe_consulta"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :disabled="!fields_consulta_medica.isEditing"
                        v-model="fields_consulta_medica.fe_consulta.fe_consulta"
                        :error-messages="errors"
                        :prepend-icon=icons.mdiCalendar
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="fields_consulta_medica.fe_consulta.fe_consulta"
                    :date-format="date => new Date(date).toDateString()" 
                    :formatted-value.sync="fields_consulta_medica.fe_consulta.fe_consulta"
                    locale="es"
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(fields_consulta_medica.fe_consulta.fe_consulta)"
                    >
                        Guardar
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
                </v-row>
            </validation-provider>

      </div>
      <div>
            <label>{{ fields_consulta_medica.hh_consulta.caracteristicas.label }}</label>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                          v-slot="{ errors }"
                          name="fields_consulta_medica.hh_consulta.hh_consulta"
                          :rules=fields_consulta_medica.hh_consulta.caracteristicas.required
                      >
                          <v-text-field                                
                              :disabled="!fields_consulta_medica.isEditing"
                              :error-messages="errors"
                              v-model="fields_consulta_medica.hh_consulta.hh_consulta"
                              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                              type="time"
                                v-bind="attrs"
                                v-on="on"
                              label="">
                          </v-text-field>                      
                      </validation-provider>
                    </template>
                    <span>{{fields_consulta_medica.hh_consulta.caracteristicas.tooltip  }} </span>
                  </v-tooltip>              

      </div>
        <div>         
                <validation-provider
                  v-slot="{ errors }"
                  name="fields_consulta_medica.nu_tiempo_estimado.nu_tiempo_estimado"
                  :rules=fields_consulta_medica.nu_tiempo_estimado.caracteristicas.required
                >
                    <v-select
                      v-model="fields_consulta_medica.nu_tiempo_estimado.nu_tiempo_estimado"
                      :items="d_tiempo_estimado"
                      class="input-group--focused text-lg pt-4"
                      :error-messages="errors"
                      :label=fields_consulta_medica.nu_tiempo_estimado.caracteristicas.label
                      dense
                      :disabled=!fields_consulta_medica.isEditing                            
                    ></v-select>                      
                </validation-provider>   
        </div>

      <div>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                          v-slot="{ errors }"
                          name="fields_consulta_medica.tx_motivo.tx_motivo"
                          :rules=fields_consulta_medica.tx_motivo.caracteristicas.required
                      >
                          <v-textarea
                              v-model="fields_consulta_medica.tx_motivo.tx_motivo"
                              :disabled="!fields_consulta_medica.isEditing"
                              auto-grow
                              class="input-group--focused text-lg"
                              :error-messages="errors"
                                v-bind="attrs"
                                v-on="on"
                              :label=fields_consulta_medica.tx_motivo.caracteristicas.label
                          ></v-textarea>                      
                      </validation-provider>     
                    </template>
                    <span>{{fields_consulta_medica.tx_motivo.caracteristicas.tooltip  }} </span>
                  </v-tooltip>              

      </div>
<!--      <div>
            <validation-provider
                v-slot="{ errors }"
                name="fields_consulta_medica.tx_diagnostico"
                rules="required"
            >
                <v-textarea
                        :disabled="!fields_consulta_medica.isEditing"
                    v-model="fields_consulta_medica.tx_diagnostico"
                    auto-grow
                    class="input-group--focused text-lg"
                    :error-messages="errors"
                    label="Diagnostico"
                    required
                ></v-textarea>                      
            </validation-provider>     

      </div>
  -->
                      <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-end">

                        <div>
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          
                        </div>
                        <div>

                                <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                  Procesando...
                                </button>                                                        
                                <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                  Guardar
                                </button>                                                        

                                <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                </button>                                                        
                      

                                <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiContentSave }}
                                                </v-icon>
                                </button>                          
                        </div>
                      </div>
                    <!-- Fin Boton de Navegacion -->    
    </ValidationObserver>
    </div>          
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { READ_ESPECIALIDAD_USUARIO, READ_CENTRO_MEDICO_USUARIO} from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
const moment = require('moment');
import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiStopCircleOutline,
  mdiContentSave
} from '@mdi/js'


export default {
  name: 'ConsultaMedicaDatosBasicos',
  data () {
    return {
        tab: null,
        errors_cantidad: '',
        panel: [0],
        dialog: false,
        dialogDelete: false,
        dias_validos: 0,
        d_tiempo_estimado: [
                  { value: 10, text: '10 min' },
                  { value: 15, text: '15 min' },
                  { value: 20, text: '20 min' },
                  { value: 25, text: '25 min' },
                  { value: 30, text: '30 min' },
                  { value: 35, text: '35 min' },
                  { value: 40, text: '40 min' },
                  { value: 45, text: '45 min' },
                  { value: 50, text: '50 min' },
                  { value: 55, text: '55 min' },
                  { value: 60, text: '60 min' },
                  { value: 90, text: '90 min o 1 hora y media' },
                  { value: 120, text: '120 min o 2 horas' },
                ],              

/*        fields_consulta_medica: {
            tx_motivo: '',
            tx_diagnostico: '',
            fe_consulta: '',
            hh_consulta: '',
            json_centro_medico: '',
            json_especialidad: '',
            btn_guardar: 'false',
            nu_id_consulta: '',
            nu_id_paciente: ''
        },        
        */
        modal: false,                 
        medicamentos: [ 
                { 
                    "nu_id_medicamento": "1", 
                    "tx_medicamento": "Losartan Potasico", 
                    "tx_presentacion": 'Tabletas de 50mg' 
                }, 
                { 
                    "nu_id_medicamento": "2", 
                    "tx_medicamento": "Clorace", 
                    "tx_presentacion": 'Tabletas de 500mg' 
                }, 
        ],
        frecuencia_medida:  [ 
                        { "nu_id_frecuencia_medida": "1", "tx_frecuencia_medida": "Horas", "nu_conversion": 1, "tx_sinonimo": 'hora (s)' }, 
                        { "nu_id_frecuencia_medida": "2", "tx_frecuencia_medida": "Diaria", "nu_conversion": 24, "tx_sinonimo": 'dia (s)' },
                        { "nu_id_frecuencia_medida": "3", "tx_frecuencia_medida": "Semanal", "nu_conversion": 168,"tx_sinonimo": 'semana (s)' },
                        { "nu_id_frecuencia_medida": "4", "tx_frecuencia_medida": "Mensual", "nu_conversion": 672,"tx_sinonimo": 'mes (es)' },
                        { "nu_id_frecuencia_medida": "5", "tx_frecuencia_medida": "Anual", "nu_conversion": 8064, "tx_sinonimo": 'año (s)' },
                         { "nu_id_frecuencia_medida": "6", "tx_frecuencia_medida": "Permanente", "nu_conversion": 8064, "tx_sinonimo": 'permanente' },
                        ],
        duracion_medida:    [ 
                        { "nu_id_duracion_medida": "2", "tx_duracion_medida": "Día (s)", "nu_conversion": 1 }, 
                        { "nu_id_duracion_medida": "3", "tx_duracion_medida": "Semana (s)", "nu_conversion": 7 },
                        { "nu_id_duracion_medida": "4", "tx_duracion_medida": "Mes (es)", "nu_conversion": 30.4 },
                        { "nu_id_duracion_medida": "5", "tx_duracion_medida": "Año (os)", "nu_conversion": 365 },
                        { "nu_id_duracion_medida": "6", "tx_duracion_medida": "Permanente", "nu_conversion": null } 
                        ],
        especialidades: [],
        centros_medicos: [],
        loading4: false, 
        isLoading: false,
        right: null,
        selected: {
            json_especialidad: [],
            json_centro_medico: [],
            json_frecuencia_medida: []
        },        

    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
        mdiStopCircleOutline,
        mdiContentSave
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },    
  },     
  computed: {
    ...mapGetters(['currentUser','fields_consulta_medica']),
    c_frecuencia () {
        return this.editedItem.json_frecuencia_medida ? 'Cada cuantas(os) ' + this.editedItem.json_frecuencia_medida.tx_sinonimo: 'Cantidad por frecuencia'
    },        
    c_duracion () {
        return this.editedItem.json_duracion_medida ? 'Por cuantas(os) ' + this.editedItem.json_duracion_medida.tx_duracion_medida: 'Período de duración del tratamiento'
    },        
    formTitle () {
    return this.editedIndex === -1 ? 'Registrar medicamento' : 'Editar medicamento'
    },        
    fecha_reg_max() {
       return new Date().toISOString().slice(0,10);

    }, 
    notificar() {
       return this.noti;
    },           
    edad() {
        const today = new Date();
        var edad
        var fecha2 = moment(today);
        console.log("fecha2: " + fecha2)      
        var fecha1 = moment(this.send_consulta_medica.paciente.fe_nacimiento);      
        edad = fecha2.diff(fecha1, 'years')
        return edad
    },
    listaMedicamentos () {
      return this.medicamentos.map(entry => {
        console.log("medicamentos : ", entry.tx_medicamento)
        const Description = entry.tx_medicamento.length > this.descriptionLimit
          ? entry.tx_medicamento.slice(0, this.descriptionLimit) + '...'
          : entry.tx_medicamento

        return Object.assign({}, entry, { Description })
      })
    },   
    listaFrecuenciaMedida () {
      return this.frecuencia_medida.map(entry => {
        console.log("tx_frecuencia_medida : ", entry.tx_frecuencia_medida)
        const Description = entry.tx_frecuencia_medida.length > this.descriptionLimit
          ? entry.tx_frecuencia_medida.slice(0, this.descriptionLimit) + '...'
          : entry.tx_frecuencia_medida

        return Object.assign({}, entry, { Description })
      })
    },       
    listaDuracionMedida () {
      var nu_id_frecuencia_medida = this.editedItem.json_frecuencia_medida.nu_id_frecuencia_medida
      return this.duracion_medida.map(entry => {
        const Description = entry.tx_duracion_medida.length > this.descriptionLimit
          ? entry.tx_duracion_medida.slice(0, this.descriptionLimit) + '...'
          : entry.tx_duracion_medida

        return Object.assign({}, entry, { Description })
      }).filter(function (x) {
                    if (x.nu_id_duracion_medida >= nu_id_frecuencia_medida) {
                        return { tx_duracion_medida: x.tx_duracion_medida, nu_duracion_cantidad: x.nu_duracion_cantidad };
                    }

      })


      
    },   
    listaEspecialidades () {
      return this.especialidades.map(entry => {
        const Description = entry.tx_especialidad.length > this.descriptionLimit
          ? entry.tx_especialidad.slice(0, this.descriptionLimit) + '...'
          : entry.tx_especialidad

        return Object.assign({}, entry, { Description })
      })

    },   
    listaCentrosMedicos () {
      return this.centros_medicos.map(entry => {
        const Description = entry.tx_razon_social.length > this.descriptionLimit
          ? entry.tx_razon_social.slice(0, this.descriptionLimit) + '...'
          : entry.tx_razon_social

        return Object.assign({}, entry, { Description })
      })
    },   
  },
  props: {
    send_consulta_medica: Object
  },
  components: {

  },    
  methods: {
    verificar_especialidades() {
        this.especialidades = this.fields_consulta_medica.json_centro_medico.json_centro_medico.especialidades
        console.log("selected.json_centro_medico", this.fields_consulta_medica.json_centro_medico.json_centro_medico.especialidades)
    },                 
    searchEspecialidad() {
      this.$store.dispatch(READ_ESPECIALIDAD_USUARIO, {username: this.currentUser.username}).then(response => {
          for (var prop in response.rest.items) {
            this.especialidades = JSON.parse(response.rest.items[prop]["json_especialidades"])
              console.log("READ_ESPECIALIDAD_USUARIO: ", response.rest.items[prop]["json_especialidades"])
          }

      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },           
    searchCentroMedico() {
        //let aa = [];

      this.$store.dispatch(READ_CENTRO_MEDICO_USUARIO, {username: this.currentUser.username}).then(response => {
          var hh = []
          var yy = {}
          for (var prop in response.rest.items) {
              yy = {}
                //aa.push(JSON.parse(response.rest.items[prop]["json_centro_medico"]))
                for (var prop_esp in  JSON.parse(response.rest.items[prop]["json_centro_medico"])) {
                   //ff.push(JSON.parse(response.rest.items[prop]["json_centro_medico"])[prop_esp]["json_especialidades"])
                    for (var prop_esp1 in  JSON.parse(response.rest.items[prop]["json_centro_medico"])[prop_esp]["json_especialidades"]) {
         //                   console.log("READ_CENTRO_MEDICO_USUARIO 021: ", JSON.parse(response.rest.items[prop]["json_centro_medico"])[prop_esp]["json_especialidades"][prop_esp1])
                            hh.push(JSON.parse(response.rest.items[prop]["json_centro_medico"])[prop_esp]["json_especialidades"][prop_esp1])
                    }
                    yy = { qq: JSON.parse(response.rest.items[prop]["json_centro_medico"])}

                }
          }

          for (var prop1 in yy) {
              for (var prop2 in yy[prop1]) {
                  yy[prop1][prop2]["json_centro_medico"]["especialidades"] = yy[prop1][prop2]["json_especialidades"]
                console.log("READ_CENTRO_MEDICO_USUARIO 02 yy: ", prop2, yy[prop1][prop2]["json_centro_medico"] )
                  this.centros_medicos.push(yy[prop1][prop2]["json_centro_medico"])
              }
          }

    
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      

    },   
    async limpiar () {
        for (const property in this.fields_consulta_medica) {
          if(typeof this.fields_consulta_medica[property] === 'object') {
            console.log('property limpiar: ', property, this.fields_consulta_medica[property]['caracteristicas']['default'])
            if (this.fields_consulta_medica[property]['caracteristicas']['default'] != '') {
              this.fields_consulta_medica[property][property] = this.fields_consulta_medica[property]['caracteristicas']['default']
            } else {
              this.fields_consulta_medica[property][property] = ''
            }
              
          }
        }


    },
    async guardar () {
      const isValid = await this.$refs.datos_basicos.validate()
      if (!isValid) {
        return true
      }
      
      
      //this.fields_consulta_medica.nu_id_paciente.nu_id_paciente = this.send_consulta_medica.paciente.nu_id_paciente.nu_id_paciente
      this.$emit("guardar_consulta_medica", JSON.stringify(this.fields_consulta_medica));

      


    },
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
      //this.loading4 = true 
        this.$emit("guardar_consulta_medica", JSON.stringify(this.fields_consulta_medica));

      this.fields_consulta_medica.isEditing = !this.fields_consulta_medica.isEditing     

    },

  },
  
  mounted() {
    this.searchEspecialidad()
    this.searchCentroMedico()
      if (this.send_consulta_medica.operacion === 'EDITAR' || this.send_consulta_medica.operacion === 'RESULTADO' ) {
          for (var prop in this.send_consulta_medica.paciente) {
          if(typeof this.fields_consulta_medica[prop] === 'object') {
                if (this.fields_consulta_medica[prop]['caracteristicas']['tipo'] === 'JSON') {
                  this.fields_consulta_medica[prop][prop] = JSON.parse(this.send_consulta_medica.paciente[prop])
                } else {
                  this.fields_consulta_medica[prop][prop] = this.send_consulta_medica.paciente[prop]
                }

              }
           //this.fields_consulta_medica[prop][prop] = this.send_consulta_medica.paciente[prop]
          }

    } else {
        this.limpiar()
        this.fields_consulta_medica.isEditing = true
    }    
    

  },
    
}
</script>