<template>
<div >
    <div class="p-2 border-2  rounded-md">
        <div>
            <ConsultaMedicaHeader :titulo="titulo "  />        
        </div>
        <div>
            <span class="md:pl-4 text-sm md:text-lg text-center uppercase text-blue-500">
                <!--<strong> 
                    Paciente: 
                </strong>{{ send_tratamiento.paciente.tx_nombres }} {{ send_tratamiento.paciente.tx_apellidos }}-->
            </span>
        </div>       
      <div>
          <TratamientoDatosBasicos  @guardar_tratamiento="return_tratamiento" :send_tratamiento="send_tratamiento">
          </TratamientoDatosBasicos>
      </div>       
    </div> 

</div>
</template>
<script>
import TratamientoDatosBasicos from "@/components/tratamiento/TratamientoDatosBasicos.vue";
import ConsultaMedicaHeader from "@/components/comunes/HeaderModulo.vue";
export default {
    name: 'TratamientoRegistrar',
    data() {
        return {
            titulo: "Registrar tratamiento"
        }
    },
    props: {
        send_tratamiento: Object

    },
    components: {
      TratamientoDatosBasicos,
      ConsultaMedicaHeader
    },
    methods: {        
        return_tratamiento(msg) {
        //this.buscarPersona('params')
        var guardar_tratamiento = JSON.parse(msg);
        this.select_paciente = JSON.parse(msg);
        console.log("ConsultaMedicaRegistrar: " , this.select_paciente, guardar_tratamiento);
        //console.log("ConsultaMedicaRegistrar: " , this.select_paciente, guardar_tratamiento);

        
        if (guardar_tratamiento.btn_guardar == 'true') {
            //this.searchConsultaMedica({})
            //this.fields.paciente = this.select_paciente.nu_id_representante
            console.log("Guardar ConsultaMedicaRegistrar: " + guardar_tratamiento.btn_guardar);

        } else {
            console.log("Cancelar: " + guardar_tratamiento.btn_guardar);
        }
        this.$emit("guardar_tratamiento", JSON.stringify(guardar_tratamiento));
        this.dialogConsultaMedica = false
        //this.send_paciente = msg;
        },         
    },
    mounted() {
        
        //  console.log('this.send_tratamiento: ', this.send_tratamiento.paciente.tx_nombre_completo )
        //this.titulo += " " + this.send_tratamiento.paciente.tx_nombre_completo;
    },         

  
}
</script>