<template>
    <div>
        <div class="flex justify-start">
        <div>

            
            <div class="form-check"  v-for="(grupos, item) in send_habitos"  :key="item.id">
                <div   v-for="(grupos1, item1) in send_habitos[item][0]"  :key="item1.id">

                    <div class="flex justify-between">
                        <div class="pr-3">
                            <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
                                {{ send_habitos[item][0][item1].habito }}
                            </label>

                        </div>
                        <div>
                            <input type="radio" v-model="send_habitos[item][0][item1].seleccionado" value="1">Si
                            <input type="radio" v-model="send_habitos[item][0][item1].seleccionado" value="2">No

                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Habitos",
        data() {
            return {
                v1: [],
                color: ''
            }
        },                
        props: {
            send_habitos: Object
        }
    }
</script>

<style lang="scss" scoped>

</style>