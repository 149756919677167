<template>
    <div>
<div class="accordion" id="accordionExample">
  <div class="accordion-item bg-white border border-gray-200">
    <h2 class="accordion-header mb-0" id="headingOne">
      <button class="
        accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
        aria-controls="collapseOne">
        Accordion Item #1
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
      data-bs-parent="#accordionExample">
      <div class="accordion-body py-4 px-5">

        <ValidationObserver ref="datos_basicos">
            <validation-provider
                v-slot="{ errors }"
                name="fields_historia_clinica.group_examen_fisico.tx_tension_arterial.tx_tension_arterial"
                :rules=fields_historia_clinica.group_examen_fisico.tx_tension_arterial.caracteristicas.required
            >
                <v-text-field
                v-model="fields_historia_clinica.group_examen_fisico.tx_tension_arterial.tx_tension_arterial"
                class="input-group--focused text-lg"
                :error-messages="errors"
                :label=fields_historia_clinica.group_examen_fisico.tx_tension_arterial.caracteristicas.label
                ></v-text-field>
            </validation-provider>         
            <validation-provider
                v-slot="{ errors }"
                name="fields_historia_clinica.group_examen_fisico.tx_frecuencia_cardiaca.tx_frecuencia_cardiaca"
                :rules=fields_historia_clinica.group_examen_fisico.tx_frecuencia_cardiaca.caracteristicas.required
            >
                <v-text-field
                v-model="fields_historia_clinica.group_examen_fisico.tx_frecuencia_cardiaca.tx_frecuencia_cardiaca"
                class="input-group--focused text-lg"
                :error-messages="errors"
                :label=fields_historia_clinica.group_examen_fisico.tx_frecuencia_cardiaca.caracteristicas.label
                ></v-text-field>
            </validation-provider>         
            <validation-provider
                v-slot="{ errors }"
                name="fields_historia_clinica.group_examen_fisico.tx_frecuencia_respiratoria.tx_frecuencia_respiratoria"
                :rules=fields_historia_clinica.group_examen_fisico.tx_frecuencia_respiratoria.caracteristicas.required
            >
                <v-text-field
                v-model="fields_historia_clinica.group_examen_fisico.tx_frecuencia_respiratoria.tx_frecuencia_respiratoria"
                class="input-group--focused text-lg"
                :error-messages="errors"
                :label=fields_historia_clinica.group_examen_fisico.tx_frecuencia_respiratoria.caracteristicas.label
                ></v-text-field>
            </validation-provider>         

            <validation-provider
                v-slot="{ errors }"
                name="fields_historia_clinica.group_examen_fisico.tx_temperatura.tx_temperatura"
                :rules=fields_historia_clinica.group_examen_fisico.tx_temperatura.caracteristicas.required
            >
                <v-text-field
                v-model="fields_historia_clinica.group_examen_fisico.tx_temperatura.tx_temperatura"
                class="input-group--focused text-lg"
                :error-messages="errors"
                :label=fields_historia_clinica.group_examen_fisico.tx_temperatura.caracteristicas.label
                ></v-text-field>
            </validation-provider>         
            <validation-provider
                v-slot="{ errors }"
                name="fields_historia_clinica.group_examen_fisico.tx_indice_masa_corporal.tx_indice_masa_corporal"
                :rules=fields_historia_clinica.group_examen_fisico.tx_indice_masa_corporal.caracteristicas.required
            >
                <v-text-field
                v-model="fields_historia_clinica.group_examen_fisico.tx_indice_masa_corporal.tx_indice_masa_corporal"
                class="input-group--focused text-lg"
                :error-messages="errors"
                :label=fields_historia_clinica.group_examen_fisico.tx_indice_masa_corporal.caracteristicas.label
                ></v-text-field>
            </validation-provider>         



                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cancelar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
                <div>

                        <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                            Procesando...
                        </button>                                                        
                        <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                            Guardar
                        </button>                                                        

                        <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                        </button>                                                        
                

                        <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiContentSave }}
                                        </v-icon>
                        </button>                          
                </div>
                </div>
            <!-- Fin Boton de Navegacion -->    

        </ValidationObserver>
      </div>
    </div>
  </div>
  <div class="accordion-item bg-white border border-gray-200">
    <h2 class="accordion-header mb-0" id="headingTwo">
      <button class="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
        aria-controls="collapseTwo">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
      data-bs-parent="#accordionExample">
      <div class="accordion-body py-4 px-5">
        <strong>This is the second item's accordion body.</strong> It is hidden by default,
        until the collapse plugin adds the appropriate classes that we use to style each
        element. These classes control the overall appearance, as well as the showing and
        hiding via CSS transitions. You can modify any of this with custom CSS or overriding
        our default variables. It's also worth noting that just about any HTML can go within
        the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item bg-white border border-gray-200">
    <h2 class="accordion-header mb-0" id="headingThree">
      <button class="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
        aria-controls="collapseThree">
        Accordion Item #3
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
      data-bs-parent="#accordionExample">
      <div class="accordion-body py-4 px-5">
        <strong>This is the third item's accordion body.</strong> It is hidden by default,
        until the collapse plugin adds the appropriate classes that we use to style each
        element. These classes control the overall appearance, as well as the showing and
        hiding via CSS transitions. You can modify any of this with custom CSS or overriding
        our default variables. It's also worth noting that just about any HTML can go within
        the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
</div>        

    </div>
</template>

<script>
import { UPDATE_CONSULTA_MEDICA, CREATE_CONSULTA_MEDICA} from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiStopCircleOutline,
  mdiContentSave
} from '@mdi/js'

    export default {
        name: 'ExamenFisico',
        data() {
            return {
                isLoading: false
            }
        },        
        props: {
            send_examen_fisico: Object
        },
        setup() {
            const icons = reactive({
                mdiClose,
                mdiAccount,
                mdiAlarmMultiple,
                mdiAlarmPlus,
                mdiPencil,
                mdiCalendar,
                mdiAccountHeart,
                mdiNeedle,
                mdiRadiologyBoxOutline,
                mdiCached,
                mdiDelete,
                mdiStopCircleOutline,
                mdiContentSave
            });  


            return {
            icons
            }
        },  
        computed: {
            ...mapGetters(['fields_historia_clinica']),
        },
        methods: {
            async guardar () {
            const isValid = await this.$refs.datos_basicos.validate()
            if (!isValid) {
                return true
            }
            this.isLoading = true      
            for (const property in this.isDisabled) {
                this.isDisabled[property] = false
            }
                this.$notify({
                group: 'generic',
                type: 'success',
                title: 'Mensaje importante',
                classes: 'w-full text-lg',
                text: 'Información guardada <b>satisfactoriamente</b>!'
                });      
            //this.loading4 = true 
            const formData = new FormData()
            this.fields_consulta_medica.nu_id_paciente.nu_id_paciente = this.send_consulta_medica.paciente.nu_id_paciente
            for (const property in this.fields_consulta_medica) {
                if(typeof this.fields_consulta_medica[property] === 'object') {
                if (this.fields_consulta_medica[property]['caracteristicas']['tipo'] === 'JSON') {
                    formData.append(`${property}`, JSON.stringify(this.fields_consulta_medica[property][property]))                  
                } else {
                    formData.append(`${property}`, `${this.fields_consulta_medica[property][property]}`)
                }

                }
            }


            //for (const property in this.fields_consulta_medica) {
            //    formData.append(`${property}`, `${this.fields_consulta_medica[property]}`)
            // }
            formData.append('oper', this.send_consulta_medica.paciente.operacion)
            //if (this.fields_consulta_medica.isEditing && this.fields_consulta_medica.nu_id_consulta) {
                if (this.send_consulta_medica.operacion === 'EDITAR' || this.send_consulta_medica.operacion === 'RESULTADO') {
                this.$store.dispatch(UPDATE_CONSULTA_MEDICA, formData).then(datos => {
                    this.fields_consulta_medica.btn_guardar = 'true';
                    this.fields_consulta_medica.nu_id_consulta.nu_id_consulta = datos.nu_id_consulta
                        setTimeout(() => {
                            this.isLoading = false
                            this.$emit("guardar_consulta_medica", JSON.stringify(this.fields_consulta_medica));
                            this.$notify({
                                group: 'generic',
                                type: 'success',
                                title: 'Mensaje importante',
                                classes: 'w-full text-lg',
                                text: 'Consulta ' + datos.nu_id_consulta + ' Actualizada satisfactoriamente!'
                            });      

                        },3000)  
                    
                }, error => {
                    this.fields_consulta_medica.btn_guardar = 'false';
                    this.$store.commit(SET_ERROR, error.statusText)
                })
            } else {
                this.$store.dispatch(CREATE_CONSULTA_MEDICA, formData).then(datos => {

                    this.fields_consulta_medica.btn_guardar = 'true';
                    this.fields_consulta_medica.nu_id_consulta.nu_id_consulta = datos.nu_id_consulta
                        setTimeout(() => {
                            this.isLoading = false
                            this.$emit("guardar_consulta_medica", JSON.stringify(this.fields_consulta_medica));
                            this.$notify({
                                group: 'generic',
                                type: 'success',
                                title: 'Mensaje importante',
                                classes: 'w-full text-lg',
                                text: 'Consulta ' + datos.nu_id_consulta + ' Creada satisfactoriamente!'
                            });      

                        },3000)                  
                        


                }, error => {
                    this.fields_consulta_medica.btn_guardar = 'false';
                    this.$store.commit(SET_ERROR, error.statusText)
                })

            }  

            this.fields_consulta_medica.isEditing = !this.fields_consulta_medica.isEditing     

            },
            async cancelar () {
                this.$notify({
                group: 'error',
                type: 'Info',
                title: 'Mensaje importante',
                classes: 'w-full text-lg',
                text: 'Operación cancelada!'
                });      
            //this.loading4 = true 
                this.$emit("guardar_examen_fisico", JSON.stringify(this.fields_historia_clinica));

            this.fields_historia_clinica.group_examen_fisico.isEditing = !this.fields_historia_clinica.group_examen_fisico.isEditing     

            }, 
        }
    }
</script>

<style lang="sass" scoped>

</style>