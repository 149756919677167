<template>
    <div>

        <div class="p-4 m-2 text-lg text-skin-base bg-skin-fill rounded-lg">
          <v-text-field
            v-model="search"
            clearable
            :prepend-inner-icon=icons.mdiMagnify
            label="Buscar tratamiento"
          ></v-text-field>

          <v-data-table
            :headers="headers"
            :items="tratamientos"
            :items-per-page="5"
            :search="search"        
            class="elevation-1"
          >
        <template v-slot:item.json_frecuencia_medida="{ item }">
            {{ item.nu_frecuencia_cantidad +  " " + JSON.parse(item.json_frecuencia_medida).tx_frecuencia_medida  }}
        </template>     
        <template v-slot:item.json_duracion_medida="{ item }">

            {{ item.nu_duracion_cantidad +  " " + JSON.parse(item.json_duracion_medida).tx_duracion_medida  }}
        </template>     
        <template v-slot:item.json_medicamento="{ item }">
            {{ JSON.parse(item.json_medicamento).tx_medicamento +  " (" + JSON.parse(item.json_medicamento).tx_presentacion +  ")"  }}
        </template>
        <template v-slot:item.bo_seguimiento="{ item }">
            <template v-if="item.bo_seguimiento == 'true'">
                    <span>Si</span>
            </template>
            <template v-else>
                    <span>No</span>
            </template>
        </template>          
              <template v-slot:item.actions="{ item }">
                <div class="flex">
                <v-icon
                  small
                  class="mr-2"
                  @click="resultado_consulta(item)"
                >
                  {{ icons.mdiBookPlusMultiple }}
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click="editar(item)"
                >
                  {{ icons.mdiPencil }}        
                </v-icon>
                </div>
<!--                <v-icon
                  small
                  @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                >
                  {{ icons.mdiImageSearchOutline }}                
                </v-icon>
-->                
              </template>
                <!-- name -->
                <template #[`item.tx_nombres`]="{item}">
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_nombres }}</span>
    <!--                <small>{{ item.tx_nombres }}</small> -->
                  </div>
                </template>          
                <template #[`item.tx_apellidos`]="{item}">
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_apellidos }}</span>
                  </div>
                </template>          
                <template #[`item.json_centro_medico`]="{item}">
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text--primary text-truncate">
                      {{ JSON.parse(item.json_centro_medico).tx_razon_social }} 
                      </span>
                  </div>
                </template>          
                <template #[`item.json_especialidad`]="{item}">
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text--primary text-truncate">
                      {{ JSON.parse(item.json_especialidad).tx_especialidad }} 
                      </span>
                  </div>
                </template>          

          </v-data-table>
        </div>    
        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogConsultaMedica" :value="true" @input="dialogConsultaMedica = false"                   
            >
            <v-card>
              <v-container>
              <!--<div v-if="this.send_tratamiento.operacion === 'EDITAR'" class="w-full md:w-1/2 mx-auto my-auto">
                <ConsultaMedicaRegistrar titulo="Editar "  @guardar_consulta_medica="return_consulta_medica_registro" :send_tratamiento="send_tratamiento"/>
              </div>
              <div v-if="this.send_tratamiento.operacion === 'RESULTADO'" class="w-full md:w-3/4 mx-auto my-auto">
                <ConsultaMedicaResultado titulo="Resultado "  @guardar_consulta_medica="return_consulta_medica_registro" :send_tratamiento="send_tratamiento"/>
              </div>
-->
              </v-container>

            </v-card>
          </v-dialog>             
    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'
import { READ_TRATAMIENTO } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

//import ConsultaMedicaRegistrar from "@/components/consulta_medica/ConsultaMedicaRegistrar.vue";
//import ConsultaMedicaResultado from "@/components/consulta_medica/ConsultaMedicaResultado.vue";


export default {
    name: 'TratamientoListar',
    data() {
        return {
            dialogConsultaMedica: false, 
            search: '',
            headers: [
                {
                text: 'Medicamento',
                align: 'start',
                sortable: false,
                value: 'json_medicamento',
                },
                { 
                  text: 'Dosis', 
                  value: 'tx_dosis' 
                },        
                { 
                  text: 'Frecuencia', 
                  value: 'json_frecuencia_medida' 
                },        
                { 
                  text: 'Duración', 
                  value: 'json_duracion_medida' 
                },        
                { 
                  
                  text: 'Fecha inicio', 
                  value: 'fe_inicio' 
                },        
                { 
                  text: 'Hora inicio', 
                  value: 'hh_inicio' 
                },        
                { 
                  text: 'Hacer seguimiento a este medicamento', 
                  value: 'bo_seguimiento' 
                },        
                { 
                  text: 'Actions', 
                  value: 'actions', 
                  sortable: false 
                },
            ],
        }
    },
    props: {
        tratamientos: [],
        send_tratamiento: Object      
    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_tratamiento.paciente = id
      this.send_tratamiento.operacion = 'EDITAR'
      this.dialogConsultaMedica = true
    },
    resultado_consulta(id) {
      console.log('Editar id: ', id) // someValue
      this.send_tratamiento.paciente = id
      this.send_tratamiento.operacion = 'RESULTADO'
      this.dialogConsultaMedica = true

    },      
    return_consulta_medica_registro(msg) {
    //this.buscarPersona('params')
    var guardar_paciente = JSON.parse(msg);
    //this.select_paciente = JSON.parse(msg);
    console.log("return_consulta_medica guardar_paciente: " , guardar_paciente);

    
    if (guardar_paciente.btn_guardar == 'true') {
        this.searchConsultaMedica({})
        //this.fields.paciente = this.select_paciente.nu_id_representante
        console.log("Guarda ultima: " + guardar_paciente.btn_guardar);
    } else {
        console.log("Cancelar: " + guardar_paciente.btn_guardar);
    }
    this.$emit("guardar_paciente", guardar_paciente);
    this.dialogConsultaMedica = false
    //this.send_tratamiento = msg;
    },    

    searchTratamiento() {
    console.log("readTratamiento ", this.send_tratamiento.paciente.nu_id_paciente)

        this.$store.dispatch(READ_TRATAMIENTO , {nu_id_paciente: this.send_tratamiento.paciente.nu_id_paciente}).then(response => {
        //this.$store.commit(SET_CITAS, response.rest.items)
        this.tratamientos = response.rest.items
        console.log("readTratamiento ", response.rest.items)

        //console.log( "events mounted: ", response.rest.items)


        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })
    },
  
    }, 
    components: {
      //ConsultaMedicaRegistrar,
      //ConsultaMedicaResultado
    },
    mounted() {
      //this.searchTratamiento({})
    }     
}
</script>