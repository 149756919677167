<template>
<div class="">
    <div class="w-full p-0 md:pl-10 md:pr-10">
    <ValidationObserver ref="datos_basicos">

      <div v-if="send_tratamiento.operacion === 'EDITAR'" class="flex justify-center items-center" @click="fields_tratamiento.isEditing = !fields_tratamiento.isEditing ">
          <div  v-if="fields_tratamiento.isEditing === true" class="cursor-pointer py-2 px-6 mr-1 border-2 rounded-lg w-12 flex justify-center items-center bg-skin-button-muted">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiStopCircleOutline }}
                  </v-icon>
              </div>

              <div class="text-white">
                Cancelar edición
              </div>

          </div>  
          <div v-else   class="cursor-pointer py-2 px-6 mr-1 border-2 rounded-lg w-12 flex justify-center items-center bg-skin-button-accent">
              <div class="pr-2">
                  <v-icon
                  color="white"
                  >                                
                  {{ icons.mdiPencil }}
                  SKSKSKSK
                  </v-icon>
              </div>

              <div class="text-white">
                Editar
              </div>

          </div>
      </div>
      <div>
              <validation-provider
                  v-slot="{ errors }"
                  name="fields_tratamiento.json_medicamento.json_medicamento"
                  :rules=fields_tratamiento.json_medicamento.caracteristicas.required
              >
                  <v-autocomplete
                      v-model="fields_tratamiento.json_medicamento.json_medicamento"
                      :items="listaMedicamentos"
                      :loading="isLoading"
                      :error-messages="errors"
                      hide-no-data
                      hide-selected
                      item-text="tx_medicamento"
                      item-value="nu_id_medicamento"
                      :label=fields_tratamiento.json_medicamento.caracteristicas.label
                      :placeholder=fields_tratamiento.json_medicamento.caracteristicas.placeholder
                      prepend-icon=""
                      return-object
                  >
                      <template v-slot:selection="data">
                          {{ data.item.tx_medicamento +  " (" + data.item.tx_presentacion +  ")" }}
                      </template>                                                                    
                      <template v-slot:item="data">
                          <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                          </template>
                          <template v-else>
                          <v-list-item-content>
                              <v-list-item-title v-html="data.item.tx_medicamento"></v-list-item-title>
                              <v-list-item-subtitle v-html="data.item.tx_presentacion"></v-list-item-subtitle>
                          </v-list-item-content>
                          </template>
                      </template>                                                                    
                  
                  </v-autocomplete>
          </validation-provider>   

      </div>
        <div>
              <validation-provider
              v-slot="{ errors }"
              name="fields_tratamiento.tx_dosis.tx_dosis"
              :rules=fields_tratamiento.tx_dosis.caracteristicas.required
              >
                  <v-text-field                                
                      v-model='fields_tratamiento.tx_dosis.tx_dosis'
                      :placeholder=fields_tratamiento.tx_dosis.caracteristicas.placeholder
                      :error-messages="errors"
                      :label=fields_tratamiento.tx_dosis.caracteristicas.label
                  >
                  </v-text-field>                      
          </validation-provider>                    

        </div>
      <div>
            <label>{{ fields_tratamiento.fe_inicio.caracteristicas.label }}</label>
            <validation-provider
                v-slot="{ errors }"
                name="fields_tratamiento.fe_inicio.fe_inicio"
                rules="required"
            >
                <v-row class="mt-2 ml-2">
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="fields_tratamiento.fe_inicio.fe_inicio"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :disabled="!fields_tratamiento.isEditing"
                        v-model="fields_tratamiento.fe_inicio.fe_inicio"
                        :error-messages="errors"
                        :prepend-icon=icons.mdiCalendar
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="fields_tratamiento.fe_inicio.fe_inicio"
                    :date-format="date => new Date(date).toDateString()" 
                    :formatted-value.sync="fields_tratamiento.fe_inicio.fe_inicio"
                    locale="es"
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(fields_tratamiento.fe_inicio.fe_inicio)"
                    >
                        Guardar
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
                </v-row>
            </validation-provider>

      </div>
      <div>
            <label>{{ fields_tratamiento.hh_inicio.caracteristicas.label }}</label>
            <validation-provider
                v-slot="{ errors }"
                name="fields_tratamiento.hh_inicio.hh_inicio"
                :rules=fields_tratamiento.hh_inicio.caracteristicas.required
            >
                <v-text-field                                
                    :error-messages="errors"
                    v-model="fields_tratamiento.hh_inicio.hh_inicio"
                    pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                    type="time"
                    label="">
                </v-text-field>                      
            </validation-provider>

      </div>
      <div>
              <validation-provider
              v-slot="{ errors }"
              name="fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida"
              :rules=fields_tratamiento.json_frecuencia_medida.caracteristicas.required
              >

                  <v-autocomplete
                      v-model="fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida"
                      :items="listaFrecuenciaMedida"
                      :loading="isLoading"
                      :error-messages="errors"
                      hide-no-data
                      hide-selected
                      item-text="tx_frecuencia_medida"
                      item-value="nu_id_frecuencia_medida"
                      :label=fields_tratamiento.json_frecuencia_medida.caracteristicas.label 
                      :placeholder=fields_tratamiento.json_frecuencia_medida.caracteristicas.placeholder
                      prepend-icon=""
                      return-object
                      @change="verificar_frecuencia()"                        
                  ></v-autocomplete>
              </validation-provider>                    

      </div>
      <div>
              <validation-provider
              v-slot="{ errors }"
              name="fields_tratamiento.nu_frecuencia_cantidad.nu_frecuencia_cantidad"
              :rules=fields_tratamiento.nu_frecuencia_cantidad.caracteristicas.required
              >
                  <v-text-field                                
                      v-model='fields_tratamiento.nu_frecuencia_cantidad.nu_frecuencia_cantidad'
                      :placeholder=c_frecuencia
                      :label=c_frecuencia
                      :error-messages="errors"
                      @change="verificar_frecuencia_cantidad()"                        
                  >
                  </v-text-field>                      
              </validation-provider>                    

      </div>
      <div>
                <validation-provider
                v-slot="{ errors }"
                name="fields_tratamiento.json_duracion_medida.json_duracion_medida"
                :rules=fields_tratamiento.json_duracion_medida.caracteristicas.required
                >
                    <v-autocomplete
                        v-model="fields_tratamiento.json_duracion_medida.json_duracion_medida"
                        :items="listaDuracionMedida"
                        :loading="isLoading"
                        :error-messages="errors"
                        hide-no-data
                        hide-selected
                        item-text="tx_duracion_medida"
                        item-value="nu_id_duracion_medida"
                        :placeholder=fields_tratamiento.json_duracion_medida.caracteristicas.placeholder
                        :label=fields_tratamiento.json_duracion_medida.caracteristicas.label
                        prepend-icon=""
                        return-object
                        @change="verificar_duracion()"                        
                    ></v-autocomplete>
                </validation-provider>                    

      </div>
        <div v-if="isEditingDuracionCantidad">                                                
                <validation-provider
                name="fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad"
                :rules=fields_tratamiento.nu_duracion_cantidad.caracteristicas.required 
                >

                    <v-text-field                                
                        v-model='fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad'
                        :error-messages="errors_cantidad"
                        :placeholder=c_duracion
                        @blur="verificar_duracion_cantidad()"
                        :label=c_duracion
                    >
                    </v-text-field>                      
                </validation-provider>    

        </div>
        <div>
              <validation-provider
                  v-slot="{ errors }"                                        
                  name="fields_tratamiento.bo_seguimiento.bo_seguimiento"
                  :rules=fields_tratamiento.bo_seguimiento.caracteristicas.required
              >
                  <v-radio-group
                      v-model="fields_tratamiento.bo_seguimiento.bo_seguimiento"
                      :error-messages="errors"
                      :label=fields_tratamiento.bo_seguimiento.caracteristicas.label
                      row
                  >
                      <v-radio
                          label="Si"
                          value="true"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="false"
                      ></v-radio>
                  </v-radio-group>
              </validation-provider>

        </div>
                      <!-- Inicio Boton de Navegacion -->
                    <div class="flex justify-end">

                        <div>
                                <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                            Cancelar
                                </button>                          
                                <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiStopCircleOutline }}
                                                </v-icon>
                                </button>                          
                          
                        </div>
                        <div>

                                <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                  Procesando...
                                </button>                                                        
                                <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                  Guardar
                                </button>                                                        

                                <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                                    <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                        <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                          viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                        <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                          <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                            <animateTransform
                                              attributeName="transform"
                                              dur="2s"
                                              type="rotate"
                                              from="0 50 48"
                                              to="360 50 52"
                                              repeatCount="indefinite" />
                                            
                                          </circle>
                                        </svg>
                                    </div>
                                </button>                                                        
                      

                                <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                                <v-icon
                                                color="white"
                                                >                                
                                                {{ icons.mdiContentSave }}
                                                </v-icon>
                                </button>                          
                        </div>
                      </div>
                    <!-- Fin Boton de Navegacion -->    
    </ValidationObserver>
    </div>          
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { UPDATE_TRATAMIENTO, CREATE_TRATAMIENTO, READ_ESPECIALIDAD_USUARIO, READ_CENTRO_MEDICO_USUARIO} from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
const moment = require('moment');
import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiStopCircleOutline,
  mdiContentSave
} from '@mdi/js'


export default {
  name: 'TratamientoDatosBasicos',
  data () {
    return {
        tab: null,
        errors_cantidad: '',
        isEditing: true,
        isEditingFrecuenciaCantidad: true,
        isEditingDuracionCantidad: true,        
        panel: [0],
        dialog: false,
        dialogDelete: false,
        dias_validos: 0,
        d_tiempo_estimado: [
                  { value: 10, text: '10 min' },
                  { value: 15, text: '15 min' },
                  { value: 20, text: '20 min' },
                  { value: 25, text: '25 min' },
                  { value: 30, text: '30 min' },
                  { value: 35, text: '35 min' },
                  { value: 40, text: '40 min' },
                  { value: 45, text: '45 min' },
                  { value: 50, text: '50 min' },
                  { value: 55, text: '55 min' },
                  { value: 60, text: '60 min' },
                  { value: 90, text: '90 min o 1 hora y media' },
                  { value: 120, text: '120 min o 2 horas' },
                ],              

/*        fields_tratamiento: {
            tx_motivo: '',
            tx_diagnostico: '',
            fe_consulta: '',
            hh_consulta: '',
            json_centro_medico: '',
            json_especialidad: '',
            btn_guardar: 'false',
            nu_id_consulta: '',
            nu_id_paciente: ''
        },        
        */
        modal: false,                 
        medicamentos: [ 
                { 
                    "nu_id_medicamento": "1", 
                    "tx_medicamento": "Losartan Potasico", 
                    "tx_presentacion": 'Tabletas de 50mg' 
                }, 
                { 
                    "nu_id_medicamento": "2", 
                    "tx_medicamento": "Clorace", 
                    "tx_presentacion": 'Tabletas de 500mg' 
                }, 
        ],
        frecuencia_medida:  [ 
                        { "nu_id_frecuencia_medida": "1", "tx_frecuencia_medida": "Horas", "nu_conversion": 1, "tx_sinonimo": 'hora (s)' }, 
                        { "nu_id_frecuencia_medida": "2", "tx_frecuencia_medida": "Diaria", "nu_conversion": 24, "tx_sinonimo": 'dia (s)' },
                        { "nu_id_frecuencia_medida": "3", "tx_frecuencia_medida": "Semanal", "nu_conversion": 168,"tx_sinonimo": 'semana (s)' },
                        { "nu_id_frecuencia_medida": "4", "tx_frecuencia_medida": "Mensual", "nu_conversion": 672,"tx_sinonimo": 'mes (es)' },
                        { "nu_id_frecuencia_medida": "5", "tx_frecuencia_medida": "Anual", "nu_conversion": 8064, "tx_sinonimo": 'año (s)' },
                         { "nu_id_frecuencia_medida": "6", "tx_frecuencia_medida": "Permanente", "nu_conversion": 8064, "tx_sinonimo": 'permanente' },
                        ],
        duracion_medida:    [ 
                        { "nu_id_duracion_medida": "2", "tx_duracion_medida": "Día (s)", "nu_conversion": 1 }, 
                        { "nu_id_duracion_medida": "3", "tx_duracion_medida": "Semana (s)", "nu_conversion": 7 },
                        { "nu_id_duracion_medida": "4", "tx_duracion_medida": "Mes (es)", "nu_conversion": 30.4 },
                        { "nu_id_duracion_medida": "5", "tx_duracion_medida": "Año (os)", "nu_conversion": 365 },
                        { "nu_id_duracion_medida": "6", "tx_duracion_medida": "Permanente", "nu_conversion": null } 
                        ],
        especialidades: [],
        centros_medicos: [],
        loading4: false, 
        isLoading: false,
        right: null,
        selected: {
            json_especialidad: [],
            json_centro_medico: [],
            json_frecuencia_medida: []
        },        

    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
        mdiStopCircleOutline,
        mdiContentSave
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },    
  },     
  computed: {
    ...mapGetters(['currentUser','fields_tratamiento']),
    c_frecuencia () {
        return this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida ? 'Cada cuantas(os) ' + this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida.tx_sinonimo: 'Cantidad por frecuencia'
    },        
    c_duracion () {
        return this.fields_tratamiento.json_duracion_medida.json_duracion_medida ? 'Por cuantas(os) ' + this.fields_tratamiento.json_duracion_medida.json_duracion_medida.tx_duracion_medida: 'Período de duración del tratamiento'
    },        
    formTitle () {
    return this.editedIndex === -1 ? 'Registrar medicamento' : 'Editar medicamento'
    },        
    fecha_reg_max() {
       return new Date().toISOString().slice(0,10);

    }, 
    notificar() {
       return this.noti;
    },           
    edad() {
        const today = new Date();
        var edad
        var fecha2 = moment(today);
        console.log("fecha2: " + fecha2)      
        var fecha1 = moment(this.send_tratamiento.paciente.fe_nacimiento);      
        edad = fecha2.diff(fecha1, 'years')
        return edad
    },
    listaMedicamentos () {
      return this.medicamentos.map(entry => {
        console.log("medicamentos : ", entry.tx_medicamento)
        const Description = entry.tx_medicamento.length > this.descriptionLimit
          ? entry.tx_medicamento.slice(0, this.descriptionLimit) + '...'
          : entry.tx_medicamento

        return Object.assign({}, entry, { Description })
      })
    },   
    listaFrecuenciaMedida () {
      return this.frecuencia_medida.map(entry => {
        console.log("tx_frecuencia_medida : ", entry.tx_frecuencia_medida)
        const Description = entry.tx_frecuencia_medida.length > this.descriptionLimit
          ? entry.tx_frecuencia_medida.slice(0, this.descriptionLimit) + '...'
          : entry.tx_frecuencia_medida

        return Object.assign({}, entry, { Description })
      })
    },       
    listaDuracionMedida () {
      var nu_id_frecuencia_medida = this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida.nu_id_frecuencia_medida
      return this.duracion_medida.map(entry => {
        console.log("listaDuracionMedida : ", nu_id_frecuencia_medida)
        const Description = entry.tx_duracion_medida.length > this.descriptionLimit
          ? entry.tx_duracion_medida.slice(0, this.descriptionLimit) + '...'
          : entry.tx_duracion_medida

        return Object.assign({}, entry, { Description })
      }).filter(function (x) {
                    if (x.nu_id_duracion_medida >= nu_id_frecuencia_medida) {
                        return { tx_duracion_medida: x.tx_duracion_medida, nu_duracion_cantidad: x.nu_duracion_cantidad };
                    }

      })
      
    },   
    listaEspecialidades () {
      return this.especialidades.map(entry => {
        const Description = entry.tx_especialidad.length > this.descriptionLimit
          ? entry.tx_especialidad.slice(0, this.descriptionLimit) + '...'
          : entry.tx_especialidad

        return Object.assign({}, entry, { Description })
      })

    },   
    listaCentrosMedicos () {
      return this.centros_medicos.map(entry => {
        const Description = entry.tx_razon_social.length > this.descriptionLimit
          ? entry.tx_razon_social.slice(0, this.descriptionLimit) + '...'
          : entry.tx_razon_social

        return Object.assign({}, entry, { Description })
      })
    },   
  },
  props: {
    send_tratamiento: Object
  },
  components: {

  },    
  methods: {
    verificar_duracion() {
        this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad = ''
        this.errors_cantidad = ''
        if (this.fields_tratamiento.json_duracion_medida.json_duracion_medida.nu_id_duracion_medida == "6") {
            this.isEditingDuracionCantidad = false
        } else {
            this.isEditingDuracionCantidad = true
        }
    },                 
    verificar_duracion_cantidad() {

        //if (this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida.nu_id_frecuencia_medida == "1") {
        //    const today = new Date();
        //    const today_horas = moment(today).add(this.fields_tratamiento.json_duracion_medida.json_duracion_medida.nu_id_duracion_medida, 'hours');
        //    console.log("today_horas: " + today_horas)      
        //    //var fecha2 = moment(today);
        //    //var fecha1 = moment(this.send_paciente.fe_nacimiento);      
        //    //this.edad = fecha2.diff(fecha1, 'years')            
        //}

        console.log("Duracion  no puede ser menor this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad: ", this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad)
            console.log("Duracion  no puede ser menor 0")
            this.errors_cantidad = ""
            if (this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida.nu_id_frecuencia_medida == "1") {
                if ( Number(this.dias_validos) <= 0) {
                    this.dias_validos = 1
                }
                    console.log("this.fields_tratamiento.json_duracion_medida.json_duracion_medida.nu_conversion: " ,  this.fields_tratamiento.json_duracion_medida.json_duracion_medida.nu_conversion)

                if (Number(this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad)  < this.dias_validos && Number(this.fields_tratamiento.json_duracion_medida.json_duracion_medida.nu_id_duracion_medida) == "2" ) {
                    console.log("this.dias_validos: " ,  this.dias_validos)
                    console.log("Numero de duracion: " ,  Number(this.fields_tratamiento.json_duracion_medida.json_duracion_medida.nu_conversion))
                    this.errors_cantidad = "Duración no puede ser " + this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad + ", tiene que ser mayor o igual a la frecuencia " + this.dias_validos + " días "
                    this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad = ''
                }
            } else {
                if (Number(this.fields_tratamiento.json_duracion_medida.json_duracion_medida.nu_id_duracion_medida) == Number(this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida.nu_id_frecuencia_medida)) {
                    if (Number(this.fields_tratamiento.nu_frecuencia_cantidad.nu_frecuencia_cantidad) > Number(this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad) ) {
                        console.log("Numero de duracion: " ,  Number(this.fields_tratamiento.json_duracion_medida.json_duracion_medida.nu_conversion))
                        this.errors_cantidad = "Duración no puede ser " + this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad + ", tiene que ser mayor o igual a la frecuencia " + this.fields_tratamiento.nu_frecuencia_cantidad.nu_frecuencia_cantidad;
                        this.fields_tratamiento.nu_duracion_cantidad.nu_duracion_cantidad = ''
                    }
                }
            }

    },                 
    verificar_frecuencia() {
        if (this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida.nu_id_frecuencia_medida == "1") {
            this.isEditingFrecuenciaCantidad = true
        } else {
            this.isEditingFrecuenciaCantidad = false
        }
    },                 
    verificar_frecuencia_cantidad() {
        this.dias_validos = 0
        if (this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida.nu_id_frecuencia_medida == "1") {
            const today = new Date();
            var hoy = moment(today);
            var proxima = moment(hoy.format("YYYY-MM-DD") + " " + this.fields_tratamiento.hh_inicio.hh_inicio);
            var mediahora= this.fields_tratamiento.nu_frecuencia_cantidad.nu_frecuencia_cantidad + ":00";
            var proxima_36 = proxima.add(moment.duration(mediahora));

            var myDate = new Date(hoy.format("YYYY-MM-DD") + " " + this.fields_tratamiento.hh_inicio.hh_inicio);

            console.log("myDate " + moment(myDate).format("YYYY-MM-DD HH:mm A"));
            var dias = proxima_36.diff(myDate, 'days') + 1
            console.log("Dias: ", dias)
            console.log("proxima_36: ", proxima_36.format("YYYY-MM-DD HH:mm A"))
            //var fecha2 = moment(today);
            //var fecha1 = moment(this.send_paciente.fe_nacimiento);      
            //this.edad = fecha2.diff(fecha1, 'years')            
            this.dias_validos = dias
            this.isEditingFrecuenciaCantidad = true
        } else {
            this.isEditingFrecuenciaCantidad = false
        }
    },                 
    verificar_especialidades() {
        this.especialidades = this.fields_tratamiento.json_centro_medico.json_centro_medico.especialidades
        console.log("selected.json_centro_medico", this.fields_tratamiento.json_centro_medico.json_centro_medico.especialidades)
    },                 
    searchEspecialidad() {
      this.$store.dispatch(READ_ESPECIALIDAD_USUARIO, {username: this.currentUser.username}).then(response => {
          for (var prop in response.rest.items) {
            this.especialidades = JSON.parse(response.rest.items[prop]["json_especialidades"])
              console.log("READ_ESPECIALIDAD_USUARIO: ", response.rest.items[prop]["json_especialidades"])
          }

      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },           
    searchCentroMedico() {
        //let aa = [];

      this.$store.dispatch(READ_CENTRO_MEDICO_USUARIO, {username: this.currentUser.username}).then(response => {
          var hh = []
          var yy = {}
          for (var prop in response.rest.items) {
              yy = {}
                //aa.push(JSON.parse(response.rest.items[prop]["json_centro_medico"]))
                for (var prop_esp in  JSON.parse(response.rest.items[prop]["json_centro_medico"])) {
                   //ff.push(JSON.parse(response.rest.items[prop]["json_centro_medico"])[prop_esp]["json_especialidades"])
                    for (var prop_esp1 in  JSON.parse(response.rest.items[prop]["json_centro_medico"])[prop_esp]["json_especialidades"]) {
         //                   console.log("READ_CENTRO_MEDICO_USUARIO 021: ", JSON.parse(response.rest.items[prop]["json_centro_medico"])[prop_esp]["json_especialidades"][prop_esp1])
                            hh.push(JSON.parse(response.rest.items[prop]["json_centro_medico"])[prop_esp]["json_especialidades"][prop_esp1])
                    }
                    yy = { qq: JSON.parse(response.rest.items[prop]["json_centro_medico"])}

                }
          }

          for (var prop1 in yy) {
              for (var prop2 in yy[prop1]) {
                  yy[prop1][prop2]["json_centro_medico"]["especialidades"] = yy[prop1][prop2]["json_especialidades"]
                console.log("READ_CENTRO_MEDICO_USUARIO 02 yy: ", prop2, yy[prop1][prop2]["json_centro_medico"] )
                  this.centros_medicos.push(yy[prop1][prop2]["json_centro_medico"])
              }
          }

    
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      

    },   
    async limpiar () {
        for (const property in this.fields_tratamiento) {
          if(typeof this.fields_tratamiento[property] === 'object') {
            console.log('property limpiar: ', property, this.fields_tratamiento[property]['caracteristicas']['default'])
            if (this.fields_tratamiento[property]['caracteristicas']['default'] != '') {
              this.fields_tratamiento[property][property] = this.fields_tratamiento[property]['caracteristicas']['default']
            } else {
              this.fields_tratamiento[property][property] = ''
            }
              
          }
        }


    },
    async guardar () {
      const isValid = await this.$refs.datos_basicos.validate()
      if (!isValid) {
        return true
      }
            this.isLoading = true      
      for (const property in this.isDisabled) {
        this.isDisabled[property] = false
      }
        this.$notify({
        group: 'generic',
        type: 'success',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Información guardada <b>satisfactoriamente</b>!'
        });      
      //this.loading4 = true 
      const formData = new FormData()
      console.log('Gusardar tratamiento this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida : ', this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida)
      this.fields_tratamiento.nu_id_frecuencia_medida.nu_id_frecuencia_medida = this.fields_tratamiento.json_frecuencia_medida.json_frecuencia_medida.nu_id_frecuencia_medida
      this.fields_tratamiento.nu_id_paciente.nu_id_paciente = this.send_tratamiento.paciente.nu_id_paciente.nu_id_paciente
      this.fields_tratamiento.nu_id_consulta.nu_id_consulta = this.send_tratamiento.consulta_medica.nu_id_consulta.nu_id_consulta
      
      for (const property in this.fields_tratamiento) {
        if(typeof this.fields_tratamiento[property] === 'object') {
          if (this.fields_tratamiento[property]['caracteristicas']['tipo'] === 'JSON') {
            console.log('Gusardar tratamiento: ', property, this.fields_tratamiento[property][property])

            formData.append(`${property}`, JSON.stringify(this.fields_tratamiento[property][property]))                  
          } else {
            formData.append(`${property}`, `${this.fields_tratamiento[property][property]}`)
          }

        }
      }


      //for (const property in this.fields_tratamiento) {
      //    formData.append(`${property}`, `${this.fields_tratamiento[property]}`)
     // }
      formData.append('oper', this.send_tratamiento.paciente.operacion)
      //if (this.fields_tratamiento.isEditing && this.fields_tratamiento.nu_id_consulta) {
        if (this.send_tratamiento.operacion === 'EDITAR' || this.send_tratamiento.operacion === 'RESULTADO') {
          this.$store.dispatch(UPDATE_TRATAMIENTO, formData).then(datos => {
              this.fields_tratamiento.btn_guardar = 'true';
              this.fields_tratamiento.nu_id_tratamiento.nu_id_tratamiento = datos.nu_id_tratamiento
                setTimeout(() => {
                      this.isLoading = false
                      this.$emit("guardar_tratamiento", JSON.stringify(this.fields_tratamiento));
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Tratamiento ' + datos.nu_id_tratamiento + ' Actualizada satisfactoriamente!'
                      });      

                  },3000)  
            
          }, error => {
              this.fields_tratamiento.btn_guardar = 'false';
              this.$store.commit(SET_ERROR, error.statusText)
          })
      } else {
          this.$store.dispatch(CREATE_TRATAMIENTO, formData).then(datos => {

              this.fields_tratamiento.btn_guardar = 'true';
              this.fields_tratamiento.nu_id_tratamiento.nu_id_tratamiento = datos.nu_id_tratamiento
                setTimeout(() => {
                      this.isLoading = false
                      this.$emit("guardar_tratamiento", JSON.stringify(this.fields_tratamiento));
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Tratamiento ' + datos.nu_id_tratamiento + ' Creada satisfactoriamente!'
                      });      

                  },3000)                  
                  


          }, error => {
              this.fields_tratamiento.btn_guardar = 'false';
              this.$store.commit(SET_ERROR, error.statusText)
          })

      }  

      this.fields_tratamiento.isEditing = !this.fields_tratamiento.isEditing     

    },
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
      //this.loading4 = true 
        this.$emit("guardar_tratamiento", JSON.stringify(this.fields_tratamiento));

      this.fields_tratamiento.isEditing = !this.fields_tratamiento.isEditing     

    },

  },
  
  mounted() {
    this.searchEspecialidad()
    this.searchCentroMedico()
      if (this.send_tratamiento.operacion === 'EDITAR' || this.send_tratamiento.operacion === 'RESULTADO' ) {
          for (var prop in this.send_tratamiento.paciente) {
          if(typeof this.fields_tratamiento[prop] === 'object') {
                if (this.fields_tratamiento[prop]['caracteristicas']['tipo'] === 'JSON') {
                  this.fields_tratamiento[prop][prop] = JSON.parse(this.send_tratamiento.paciente[prop])
                } else {
                  this.fields_tratamiento[prop][prop] = this.send_tratamiento.paciente[prop]
                }

              }
           //this.fields_tratamiento[prop][prop] = this.send_tratamiento.paciente[prop]
          }

    } else {
        this.limpiar()
        this.fields_tratamiento.isEditing = true
    }    
    

  },
    
}
</script>