<template>
<div class="md:flex md:items-start">
  <div class="w-1/6">

      <ul class="
        nav nav-tabs nav-justified
        flex flex-col
        flex-wrap
        list-none
        border-2
        pl-0
        mb-4
        rounded-md
      " id="tabs-tabJustify" role="tablist">
        <li class="nav-item flex-grow border-b-2"  @click="handleClick('group_historia_clinica')" role="presentation">
          <a href="#tabs-pacienteJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
            active
          " id="tabs-paciente-tabJustify"   data-bs-toggle="pill" data-bs-target="#tabs-pacienteJustify" role="tab"
            aria-controls="tabs-pacienteJustify" aria-selected="true">Paciente</a>
        </li>
        <li class="nav-item flex-grow  border-b-2" @click="handleClick('group_consulta_medica')" role="presentation">
          <a href="#tabs-consultamedicaJustify" class="
            nav-link
            w-full
            block
            font-medium
            leading-tight
            text-sm
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-consultamedica-tabJustify" ref="tabs_consultamedica_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-consultamedicaJustify" role="tab"
            aria-controls="tabs-consultamedicaJustify" aria-selected="false">
            Datos de la             
            </a>
        </li>
        <li class="nav-item flex-grow  border-b-2" @click="handleClick('group_enfermedad_actual')" role="presentation">
          <a href="#tabs-enfermedadactualJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-enfermedadactual-tabJustify" ref="tabs_enfermedadactual_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-enfermedadactualJustify" role="tab"
            aria-controls="tabs-enfermedadactualJustify" aria-selected="false">
            Enfermedad actual
            </a>
        </li>
        <li class="nav-item flex-grow  border-b-2" @click="handleClick('group_antecedentes_enfermedad_actual')" role="presentation">
          <a href="#tabs-anteenfermedadadctualJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-anteenfermedadadactual-tabJustify" ref="tabs_anteenfermedadadactual_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-anteenfermedadadctualJustify" role="tab"
            aria-controls="tabs-anteenfermedadadactualJustify" aria-selected="false">
            Antecedentes de enfermedad actual
            </a>
        </li>
        <li class="nav-item flex-grow  border-b-2" @click="handleClick('group_antecedentes_personales')" role="presentation">
          <a href="#tabs-antepersonalesctualJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-antepersonales-tabJustify" ref="tabs_antepersonales_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-antepersonalesJustify" role="tab"
            aria-controls="tabs-antepersonalesJustify" aria-selected="false">
              Antecedentes personales
            </a>
        </li>
        <li class="nav-item flex-grow  border-b-2" @click="handleClick('group_antecedentes_familiares')" role="presentation">
          <a href="#tabs-antefamiliaresJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-antefamiliares-tabJustify" ref="tabs_antefamiliares_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-antefamiliaresJustify" role="tab"
            aria-controls="tabs-antefamiliaresJustify" aria-selected="false">
              Antecedentes familiares
            </a>
        </li>
        <li class="nav-item flex-grow  border-b-2" @click="handleClick('group_examen_fisico')" role="presentation">
          <a href="#tabs-examenfisicoJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-examenfisico-tabJustify" ref="tabs_examenfisico_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-examenfisicoJustify" role="tab"
            aria-controls="tabs-examenfisicoJustify" aria-selected="false">
              Examen físico
            </a>
        </li>
        <li class="nav-item flex-grow  border-b-2" @click="handleClick('group_diagnostico')" role="presentation">
          <a href="#tabs-diagnosticoJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-diagnostico-tabJustify" ref="tabs_diagnostico_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-diagnosticoJustify" role="tab"
            aria-controls="tabs-diagnosticoJustify" aria-selected="false">
              Diagnóstico
            </a>
        </li>
        <li class="nav-item flex-grow  border-b-2" @click="handleClick('group_tratamiento')" role="presentation">
          <a href="#tabs-tratamientoJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-tratamiento-tabJustify" ref="tabs_tratamiento_tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-tratamientoJustify" role="tab"
            aria-controls="tabs-tratamientoJustify" aria-selected="false">
              Tratamiento
            </a>
        </li>
        <li class="nav-item flex-grow" @click="cerrar({})" role="presentation">
          <a href="#tabs-cerrarJustify" class="
            nav-link
            w-full
            block
            font-medium
            text-sm
            leading-tight
            border-transparent
            px-6
            py-3
            hover:border-transparent hover:bg-gray-100
            focus:border-transparent
          " id="tabs-cerrar-tabJustify" data-bs-toggle="pill" data-bs-target="#tabs-cerrarJustify" role="tab"
            aria-controls="tabs-cerrarJustify" aria-selected="false">
              Cerrar
            </a>
        </li>

      </ul>
    
  </div>
  <div class="tab-content w-full md:w-4/6 rounded-lg" id="tabs-tabContentJustify">
      <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
        <NotificacionInterna :mensaje="mensaje" />  
      </div>

      <div class="tab-pane fade active show"  id="tabs-pacienteJustify" role="tabpanel"
        aria-labelledby="tabs-paciente-tabJustify">
          <div class=" border-2 rounded-md shadow-md">
            <PacienteRegistrar titulo="" @guardar_paciente="return_paciente" :send_paciente="send_paciente"/>        
          </div>
      </div>
      <div class="tab-pane fade " id="tabs-consultamedicaJustify" role="tabpanel" aria-labelledby="tabs-consultamedica-tabJustify">
            <div class="p-2 bg-white border-2 ">
<!--              <ConsultaMedica  @guardar_consulta_medica="return_consulta_medica" :send_consulta_medica="fields_historia_clinica.group_historia_clinica">
              </ConsultaMedica>
              -->
              <!-- Pruebas -->
              <ConsultaMedica  @guardar_consulta_medica="return_consulta_medica" :send_consulta_medica="datos_historia_clinica">
              </ConsultaMedica>

            </div>
      </div>
      <div class="tab-pane fade" id="tabs-enfermedadactualJustify" role="tabpanel" aria-labelledby="tabs-enfermedadactual-tabJustify">
            <div class="p-2 bg-white border-2 rounded-md shadow-md">
                <EnfermedadActual  @guardar_enfermedad_actual="return_enfermedad_actual" :send_consulta_medica="datos_historia_clinica">
                </EnfermedadActual>
            </div>

      </div>
      <div class="tab-pane fade" id="tabs-anteenfermedadadctualJustify" role="tabpanel" aria-labelledby="tabs-anteenfermedadadactual-tabJustify">
            <div class="p-2 bg-white border-2 rounded-md shadow-md">
                <AntecedentesEnfermedadActual  @guardar_antecedentes_enfermedad_actual="return_antecedentes_enfermedad_actual" :send_consulta_medica="datos_historia_clinica">
                </AntecedentesEnfermedadActual>
            </div>

      </div>
      <div class="tab-pane fade" id="tabs-antepersonalesJustify" role="tabpanel" aria-labelledby="tabs-antepersonales-tabJustify">
            <div class="p-2 bg-white border-2 rounded-md shadow-md">
            <AntecedentesPersonales  @guardar_antecedentes_personales="return_antecedentes_personales" :send_consulta_medica="datos_historia_clinica">
            </AntecedentesPersonales>
            </div>

      </div>
      <div class="tab-pane fade" id="tabs-antefamiliaresJustify" role="tabpanel" aria-labelledby="tabs-antefamiliares-tabJustify">
            <div class="p-2 bg-white border-2 rounded-md shadow-md">
            <AntecedentesFamiliares  @guardar_antecedentes_familiares="return_antecedentes_familiares" :send_antecedentes_familiares="send_antecedentes_familiares">
            </AntecedentesFamiliares>
            </div>

      </div>
      <div class="tab-pane fade" id="tabs-examenfisicoJustify" role="tabpanel" aria-labelledby="tabs-examenfisico-tabJustify">
            <div class="p-2 bg-white border-2 rounded-md shadow-md">
            <ExamenFisico  @guardar_examen_fisico="return_examen_fisico" :send_examen_fisico="send_examen_fisico">
            </ExamenFisico>
            </div>

      </div>
      <div class="tab-pane fade" id="tabs-diagnosticoJustify" role="tabpanel" aria-labelledby="tabs-diagnostico-tabJustify">
            <div class="p-2 bg-white border-2 rounded-md shadow-md">
            <Diagnostico  @guardar_diagnostico="return_diagnostico" :send_diagnostico="send_diagnostico">
            </Diagnostico>
            </div>

      </div>
      <div class="tab-pane fade" id="tabs-tratamientoJustify" role="tabpanel" aria-labelledby="tabs-tratamiento-tabJustify">
            <div class="p-2 bg-white border-2 rounded-md shadow-md">
            <Tratamiento  @guardar_tratamiento="return_tratamiento" :send_tratamiento="datos_historia_clinica">
            </Tratamiento>
            </div>

      </div>

  </div>
  <div class="text-sm w-1/6">
      
      <div>
        <div class="accordion accordion-flush text-sm" id="accordionFlushExample">
          <div v-if="send_paciente.paciente.tx_historia"  class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
            <h2 class="accordion-header mb-0" id="flush-headingOne">
              <button class="accordion-button
            relative
            flex
            items-center
            py-4
            px-5
            text-sm 
            text-gray-800 
            text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                aria-expanded="false" aria-controls="flush-collapseOne">
                Consultas medicas anteriores
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body py-4 px-5">
                  <div class="text-xs" v-for="(item, index) in consultasmedica" :key="index">      
                    {{ item.fe_consulta }}
                    {{ item.tx_motivo }}
                  </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
            <h2 class="accordion-header mb-0" id="flush-headingTwo">
              <button class="accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            rounded-none
            px-5
            text-sm text-gray-800 text-left
            bg-white
            border-0
            transition
            focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                aria-expanded="false" aria-controls="flush-collapseTwo">
                Agenda
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                demonstrate
                the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this
                being
                filled with some actual content.</div>
            </div>
          </div>
          <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
            <h2 class="accordion-header mb-0" id="flush-headingThree">
              <button class="accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-sm text-gray-800 text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                aria-expanded="false" aria-controls="flush-collapseThree">
                Seguridad
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body py-4 px-5">
                <div class="border-t-2" v-for="(item, index) in locales.grupos" :key="index">      
                  {{ index }}
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
            <h2 class="accordion-header mb-0" id="flush-headingPlantilla">
              <button class="accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-base text-gray-800 text-left
            bg-white
            border-0
            rounded-none
            transition
            focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePlantilla"
                aria-expanded="false" aria-controls="flush-collapsePlantilla">
                Plantilla
              </button>
            </h2>
            <div id="flush-collapsePlantilla" class="accordion-collapse collapse" aria-labelledby="flush-headingPlantilla"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to
                demonstrate
                the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting
                happening here in terms of content, but just filling up the space to make it look, at least at first
                glance,
                a bit more representative of how this would look in a real-world application.</div>
            </div>
          </div>          
        </div>   
        <div v-if="send_paciente.paciente.tx_historia" class="py-4 px-5 bg-white  border-gray-200">
          <span>Última consulta</span>
            <div>
              <div>
              </div>
              <div>
                <div class="border-t-2" v-for="(item, index) in locales.grupos" :key="index">      
                  {{ locales.grupos[index].caracteristicas.label  }}
                  {{ locales.grupos[index][index]  }}
                </div>              
            </div>
            </div>

        </div>
      </div>
  </div>
</div>
</template>

<script>
import { UPDATE_CONSULTA_MEDICA, CREATE_CONSULTA_MEDICA, CREATE_PACIENTE, UPDATE_PACIENTE, MUESTRA_CONSULTA_MEDICA } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import PacienteRegistrar from "@/components/paciente/PacienteRegistrar";
import ConsultaMedica from "@/components/consulta_medica/ConsultaMedicaDatosBasicos.vue";
import EnfermedadActual from "@/components/historia_clinica/EnfermedadActual";
import AntecedentesEnfermedadActual from "@/components/historia_clinica/AntecedentesEnfermedadActual";
import AntecedentesPersonales from "@/components/historia_clinica/AntecedentesPersonales";
import AntecedentesFamiliares from "@/components/historia_clinica/AntecedentesFamiliares";
import ExamenFisico from "@/components/historia_clinica/ExamenFisico";
import Diagnostico from "@/components/historia_clinica/Diagnostico";
import Tratamiento from "@/views/tratamiento/Tratamiento";
const moment = require('moment');

  export default {
    data() {
        return {
          consultasmedica: [],
          tabs: {
            group_historia_clinica:{
              active: '',
              class: ''
            },
            group_consulta_medica:{
              active: 'active',
              class: 'show active'
            },
          },
          fields_historia_clinica_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupos:'',
            fields_historia_clinica: ''
          },
          datos_historia_clinica: {
            datos_persona: {},
            datos_historia_clinica: {},
            datos_consulta_medica: {},
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_historia_clinica']),
        hoy() {
            const today = new Date();
            var fecha2 = moment(today);
            return fecha2.format('DD-MM-YYYY');
        },            

    },    
    props: {
        send_paciente: Object,
        send_motivo: Object,
        send_enfermedad_actual: Object,
        send_antecedentes_enfermedad_actual: Object,
        send_antecedentes_personales: Object,
        send_antecedentes_familiares: Object,
        send_examen_fisico: Object,
        send_diagnostico: Object,
        send_tratamiento: Object,
        titulo: String
    },
    components: {
      PacienteRegistrar,
      ConsultaMedica,
      NotificacionInterna,
      EnfermedadActual,
      AntecedentesEnfermedadActual,
      AntecedentesPersonales,
      AntecedentesFamiliares,
      ExamenFisico,
      Diagnostico,
      Tratamiento

    },
    methods: {
        searchConsultaMedica(q) {
          console.log('Paciente: ', q)
          this.$store.dispatch(MUESTRA_CONSULTA_MEDICA, {nu_id_historia: q}).then(response => {
              this.consultasmedica = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
        },  
        handleClick(e) {
          console.log('blur', e)
          this.locales.grupos = this.fields_historia_clinica.[e]
        },      
        cerrar(msg) {
          this.$emit("guardar_historia_clinica", msg);
        },    
        ocultar_pestana() {
          for (var property in this.pestana) {
                this.pestana[property] = 'hide'
            }
        },
        mostrar_pestana(obj) {
                this.pestana[obj] = 'show'
        },
        return_examen_fisico(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);
            console.log("return_examen_fisico Paciente Listar: " , guardar);

            guardar.btn_guardar = 'true'
            if (guardar.btn_guardar == 'true') {
                //this.searchPaciente({})
                this.mensaje.satisfactorio.texto = "Registro de examen físico fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseAntecedentesPersonales')

                setTimeout(() => {
                        //this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                    this.mensaje.satisfactorio.status = false
                  },3000);             
            } else {
                this.mensaje.nosatisfactorio.texto = "Registro de examen físico cancelado"
                this.mensaje.nosatisfactorio.status = true
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
            }
            //this.$emit("guardar_paciente", guardar_paciente);
            //this.send_paciente = msg;
        },    
        return_diagnostico(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);
            console.log("return_diagnostico group_consulta_medica: " , guardar);

            guardar.btn_guardar = 'true'
            if (guardar.btn_guardar == 'true') {
                //this.searchPaciente({})
                this.mensaje.satisfactorio.texto = "Registro de examen físico fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseAntecedentesPersonales')

                setTimeout(() => {
                        //this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                    this.mensaje.satisfactorio.status = false
                  },3000);             
            } else {
                this.mensaje.nosatisfactorio.texto = "Registro de examen físico cancelado"
                this.mensaje.nosatisfactorio.status = true
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
            }
            //this.$emit("guardar_paciente", guardar_paciente);
            //this.send_paciente = msg;
        },    
        return_tratamiento(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);
            console.log("return_tratamiento: " , guardar);

            guardar.btn_guardar = 'true'
            if (guardar.btn_guardar == 'true') {
                //this.searchPaciente({})
                this.mensaje.satisfactorio.texto = "Registro de tratamiento fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseTratamiento')

                setTimeout(() => {
                        //this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                    this.mensaje.satisfactorio.status = false
                  },3000);             
            } else {
                this.mensaje.nosatisfactorio.texto = "Registro de tratamiento cancelado"
                this.mensaje.nosatisfactorio.status = true
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
            }
            //this.$emit("guardar_paciente", guardar_paciente);
            //this.send_paciente = msg;
        },    

        return_antecedentes_familiares(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);

            guardar.btn_guardar = 'true'
            if (guardar.btn_guardar == 'true') {
                //this.searchPaciente({})
                this.mensaje.satisfactorio.texto = "Registro de antecedente familiares fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseExamenFisico')

                setTimeout(() => {
                        //this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                    this.mensaje.satisfactorio.status = false
                  },3000);             
            } else {
                this.mensaje.nosatisfactorio.texto = "Registro de antecedentes familiares cancelado"
                this.mensaje.nosatisfactorio.status = true
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
            }
            //this.$emit("guardar_paciente", guardar_paciente);
            //this.send_paciente = msg;
        },    
        return_antecedentes_personales(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);

            guardar.btn_guardar = 'true'
            if (guardar.btn_guardar == 'true') {
                //this.searchPaciente({})
                this.mensaje.satisfactorio.texto = "Registro de antecedente personales fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseAntecedentesFamiliares')

                setTimeout(() => {
                        //this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                    this.mensaje.satisfactorio.status = false
                  },3000);             
            } else {
                this.mensaje.nosatisfactorio.texto = "Registro de antecedentes personales cancelado"
                this.mensaje.nosatisfactorio.status = true
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
            }
            //this.$emit("guardar_paciente", guardar_paciente);
            //this.send_paciente = msg;
        },    
            return_antecedentes_enfermedad_actual(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);

            guardar.btn_guardar = 'true'
            if (guardar.btn_guardar == 'true') {
                //this.searchPaciente({})
                this.mensaje.satisfactorio.texto = "Registro de antecedente enfermedad actual fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseAntecedentesPersonales')

                setTimeout(() => {
                        //this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                    this.mensaje.satisfactorio.status = false
                  },3000);             
            } else {
                this.mensaje.nosatisfactorio.texto = "Registro de antecedente enfermedad actual cancelado"
                this.mensaje.nosatisfactorio.status = true
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
            }
            //this.$emit("guardar_paciente", guardar_paciente);
            //this.send_paciente = msg;
        },    
        return_enfermedad_actual(msg) {
          //this.buscarPersona('params')
            var guardar = JSON.parse(msg);

            const formData = new FormData()
            console.log('guardar-group_enfermedad_actual  : ', guardar.group_enfermedad_actual.tx_enfermedad_actual.tx_enfermedad_actual)
            console.log('guardar.group_historia_clinica.nu_id_consulta.nu_id_consulta  : ', guardar.group_historia_clinica.nu_id_consulta.nu_id_consulta)
            formData.append('tx_enfermedad_actual', guardar.group_enfermedad_actual.tx_enfermedad_actual.tx_enfermedad_actual)                  
            formData.append('nu_id_consulta', guardar.group_historia_clinica.nu_id_consulta.nu_id_consulta)                  
          // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

              formData.append('oper', 'EDITAR')
              
              this.$store.dispatch(UPDATE_CONSULTA_MEDICA, formData).then(datos => {
                guardar.btn_guardar = 'true';
                //this.fields_historia_clinica.group_historia_clinica.nu_id_historia.nu_id_historia = datos.nu_id_historia
                //this.fields_historia_clinica.group_historia_clinica.tx_historia.tx_historia = datos.tx_historia
                setTimeout(() => {
                     // this.isLoading = false
                      console.log('message emit from child component', datos)
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                      });      

                  },3000)                  
                  this.$refs.tabs_enfermedadactual_tabJustify.click();

                
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

          /// hasta aca optimizar
        },    
        // Paso #2
        return_consulta_medica(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
                      console.log('guardar consulta medica: ', guardar)


            const formData = new FormData()
            guardar.nu_id_historia.nu_id_historia = this.datos_historia_clinica.datos_paciente.nu_id_historia 
            formData.append('consulta_medica', JSON.stringify(guardar))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            if (this.send_paciente.operacion === 'EDITAR') {
              formData.append('oper', 'EDITAR')
              
              this.$store.dispatch(UPDATE_CONSULTA_MEDICA, formData).then(datos => {
                guardar.btn_guardar = 'true';
                  //this.fields_historia_clinica.group_historia_clinica.nu_id_historia.nu_id_historia = datos.nu_id_historia
                //this.fields_historia_clinica.group_historia_clinica.tx_historia.tx_historia = datos.tx_historia
                setTimeout(() => {
                     // this.isLoading = false
                      console.log('message emit from child component', datos)
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                      });      

                  },3000)                  
                  this.$refs.tabs_enfermedadactual_tabJustify.click();

                
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

          } else {
            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_CONSULTA_MEDICA, formData).then(datos => {
                //this.searchPaciente({})
                //this.fields.paciente = this.select_paciente.nu_id_representante
                this.mensaje.satisfactorio.texto = "Registro de  fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.isHistoria = true
                this.datos_historia_clinica.datos_consulta_medica = JSON.parse(datos.datos_consulta_medica)

                //this.fields_historia_clinica.group_historia_clinica.nu_id_historia.nu_id_historia = datos.nu_id_historia
                //this.fields_historia_clinica.group_historia_clinica.tx_historia.tx_historia = datos.tx_historia
                setTimeout(() => {
                      this.isLoading = false
                  //    this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                      console.log('message emit from child component', datos)
                      this.mensaje.satisfactorio.status = false
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Datos  creado satisfactoriamente!'
                      });      

                  },3000)                  
                  
                  this.$refs.tabs_enfermedadactual_tabJustify.click();

                
            }, error => {
                this.$store.commit(SET_ERROR, error.statusText)
                this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                this.mensaje.nosatisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseMotivo')
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
                console.log("Cancelar: " + guardar.btn_guardar);

            })  

          }     

          /// hasta aca optimizar
            
        },    
        return_historia_clinica(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);
            guardar.btn_guardar = 'true'
            if (guardar.btn_guardar == 'true') {
                //this.searchPaciente({})
                this.mensaje.satisfactorio.texto = "Registro de  fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.datos_historia_clinica.consulta_medica = guardar
                this.ocultar_pestana()
                this.mostrar_pestana('collapseHistoriaClinica')
                setTimeout(() => {
                        //this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                    this.mensaje.satisfactorio.status = false
                  },3000);             
            } else {
                this.mensaje.nosatisfactorio.texto = "Registro de  cancelado"
                this.mensaje.nosatisfactorio.status = true
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
            }
            this.$emit("guardar_historia_clinica", guardar);
            //this.send_paciente = msg;
        },    
        // Paso #1        
        return_paciente(msg) {
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);
              console.log('guardar Actualizar: ', guardar)

            const formData = new FormData()

            formData.append('persona', JSON.stringify(guardar))

            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            if (this.send_paciente.operacion === 'EDITAR') {
              formData.append('oper', 'EDITAR')
              
              this.$store.dispatch(UPDATE_PACIENTE, formData).then(datos => {
                guardar.btn_guardar = 'true';
                this.fields_historia_clinica.group_historia_clinica.nu_id_historia.nu_id_historia = datos.nu_id_historia
                this.fields_historia_clinica.group_historia_clinica.tx_historia.tx_historia = datos.tx_historia
                this.fields_historia_clinica.group_historia_clinica.fe_apertura.fe_apertura = this.hoy
                setTimeout(() => {
                     // this.isLoading = false
                      console.log('message emit from child component', datos)
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' + datos.tx_nombre_completo + ' actualizado satisfactoriamente!'
                      });      

                  },3000)                  
                  this.$refs.tabs_consultamedica_tabJustify.click();

                
              }, error => {
                  guardar.btn_guardar = 'false';
                  this.$store.commit(SET_ERROR, error.statusText)
              })

          } else {
            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_PACIENTE, formData).then(datos => {
                //this.searchPaciente({})
                //this.fields.paciente = this.select_paciente.nu_id_representante

                console.log("datos_historia_clinica: ", datos.datos_historia_clinica)
                this.mensaje.satisfactorio.texto = "Registro de paciente fue satisfactorio"
                this.mensaje.satisfactorio.status = true
                this.isHistoria = true
                this.datos_historia_clinica.datos_persona = datos.datos_persona
                this.datos_historia_clinica.datos_historia_clinica = JSON.parse(datos.datos_historia_clinica)
                console.log("datos_historia_clinica: ", this.datos_historia_clinica.datos_historia_clinica)

                this.fields_historia_clinica.group_historia_clinica.nu_id_historia.nu_id_historia = this.datos_historia_clinica.datos_historia_clinica.nu_id_historia
                this.fields_historia_clinica.group_historia_clinica.tx_historia.tx_historia = this.datos_historia_clinica.datos_historia_clinica.tx_historia
                this.fields_historia_clinica.group_historia_clinica.fe_apertura.fe_apertura = this.hoy

                setTimeout(() => {
                      this.isLoading = false
                  //    this.$emit("guardar_paciente", JSON.stringify(this.fields_paciente));
                      console.log('message emit from child component', datos)
                      this.mensaje.satisfactorio.status = false
                      this.$notify({
                        group: 'generic',
                        type: 'success',
                        title: 'Mensaje importante',
                        classes: 'w-full text-lg',
                        text: 'Paciente ' +  datos.datos_persona + ' Creado satisfactoriamente!'
                      });      

                  },3000)                  
                  
                  this.$refs.tabs_consultamedica_tabJustify.click();

                
            }, error => {
                this.$store.commit(SET_ERROR, error.statusText)
                this.mensaje.nosatisfactorio.texto = "Registro de paciente cancelado"
                this.mensaje.nosatisfactorio.status = true
                this.ocultar_pestana()
                this.mostrar_pestana('collapseMotivo')
                setTimeout(() => {
                    this.mensaje.nosatisfactorio.status = false
                    },3000);             
                console.log("Cancelar: " + guardar.btn_guardar);

            })  

          }     
// hasta     

            console.log("Datos a enviar: " , guardar);
            console.log("Datos a enviar send_paciente 01: " , this.send_paciente);


            //this.select_paciente = JSON.parse(msg);[


        },    



    },
    mounted() {
      this.handleClick('group_consulta_medica')
      console.log('Paciente mount: ', this.send_paciente.paciente.nu_id_historia)
      /*this.locales.fields_historia_clinica_temp = this.fields_historia_clinica
      this.$store.dispatch(MUESTRA_CONSULTA_MEDICA, {nu_id_historia: this.send_paciente.paciente.nu_id_historia}).then(response => {
          this.consultasmedica = response.rest.items
          for (var prop in this.fields_historia_clinica) {
            for (var prop_1 in this.fields_historia_clinica[prop]) {
              for (var prop_2 in this.fields_historia_clinica[prop][prop_1]) {
                if (this.consultasmedica[0].[prop_2]) {
                  this.locales.fields_historia_clinica_temp[prop][prop_1][prop_1] =  this.consultasmedica[0].[prop_2]
                  console.log('fields_historia_clinica_temp: ', this.locales.fields_historia_clinica_temp[prop][prop_1][prop_1] )
                }
              }

            }
            //this.fields_historia_clinica[prop][prop] = this.consultasmedica[0][prop]
          }

      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })
      */         
    },         

    
  }
</script>

<style lang="scss" scoped>

</style>