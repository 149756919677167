<template>
    <div>
        <div class="grid grid-cols-1 gap-2">
            <TiposEnfermedades :send_tipos_enfermedades="send_tipos_enfermedades"></TiposEnfermedades>
        </div>
    </div>
</template>

<script>
import TiposEnfermedades from "@/components/historia_clinica/TiposEnfermedades";

export default {
        name:"Enfermedades",
        data() {
            return {
                isLoading: false,
                send_tipos_enfermedades: {
                    'cardiovasculares': {
                        id: '01',
                        enfermedad: 'Cardiovasculares',
                        seleccionado: ''
                    },
                    'respiratorias': {
                        id: '02',
                        enfermedad: 'Respiratorias',
                        seleccionado: ''
                    },
                    'gastrointestinales': {
                        id: '03',
                        enfermedad: 'Gastrointestinales',
                        seleccionado: ''
                    },
                    'nefrourologicas': {
                        id: '04',
                        enfermedad: 'Nefrourológicas',
                        seleccionado: ''
                    },

                },
            }
        }, 
        components: {
            TiposEnfermedades
        }               


    }
</script>

<style lang="scss" scoped>

</style>