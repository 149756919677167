<template>
    <div>

        
        <ValidationObserver ref="datos_basicos">
            <div class="accordion" id="accordionExample5">
            <div class="accordion-item bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="headingOne5">
                <button class="
                    accordion-button
                    relative
                    flex
                    items-center
                    w-full
                    py-4
                    px-5
                    text-base text-gray-800 text-left
                    bg-white
                    border-0
                    rounded-none
                    transition
                    focus:outline-none
                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne5" aria-expanded="true"
                    aria-controls="collapseOne5">
                    Hábitos tóxicos
                </button>
                </h2>
                <div id="collapseOne5" class="accordion-collapse collapse show" aria-labelledby="headingOne5">
                <div class="accordion-body py-4 px-5">
                            <Habitos :send_habitos="send_habitos_toxicos"></Habitos>
                </div>
                </div>
            </div>
            <div class="accordion-item bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="headingTwo5">
                <button class="
                    accordion-button
                    collapsed
                    relative
                    flex
                    items-center
                    w-full
                    py-4
                    px-5
                    text-base text-gray-800 text-left
                    bg-white
                    border-0
                    rounded-none
                    transition
                    focus:outline-none
                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo5" aria-expanded="false"
                    aria-controls="collapseTwo5">
                    Hábitos fisiológicos
                </button>
                </h2>
                <div id="collapseTwo5" class="accordion-collapse collapse" aria-labelledby="headingTwo5">
                <div class="accordion-body py-4 px-5">
                        <Habitos :send_habitos="send_habitos_fisiologicos"></Habitos>
                </div>
                </div>
            </div>
            <div class="accordion-item bg-white border border-gray-200">
                <h2 class="accordion-header mb-0" id="headingThree5">
                <button class="
                    accordion-button
                    collapsed
                    relative
                    flex
                    items-center
                    w-full
                    py-4
                    px-5
                    text-base text-gray-800 text-left
                    bg-white
                    border-0
                    rounded-none
                    transition
                    focus:outline-none
                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree5" aria-expanded="false"
                    aria-controls="collapseThree5">
                    Enfermedades
                </button>
                </h2>
                <div id="collapseThree5" class="accordion-collapse collapse" aria-labelledby="headingThree5">
                <div class="accordion-body py-4 px-5">
                            <Enfermedades></Enfermedades>
                </div>
                </div>
            </div>
            </div>            


                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cancelar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
                <div>

                        <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                            Procesando...
                        </button>                                                        
                        <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                            Guardar
                        </button>                                                        

                        <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                        </button>                                                        
                

                        <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiContentSave }}
                                        </v-icon>
                        </button>                          
                </div>
                </div>
            <!-- Fin Boton de Navegacion -->    

        </ValidationObserver>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"
import Habitos from "@/components/historia_clinica/Habitos";
import Enfermedades from "@/components/historia_clinica/Enfermedades";
import { UPDATE_CONSULTA_MEDICA_ANTECEDENTES_PERSONALES} from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiStopCircleOutline,
  mdiContentSave
} from '@mdi/js'
    export default {
        name: 'AntecedentesPersonales',
        data() {
            return {
                isLoading: false,
                send_habitos_toxicos: {
                    items: [{
                        'alcohol': {
                            id: '01',
                            habito: 'Consume alcohol frecuentemente?',
                            seleccionado: ''
                        },
                        'tabaco': {
                            id: '02',
                            habito: 'Fuma tabaco / cigarro frecuentemente?',
                            seleccionado: ''
                        },
                        'drogas': {
                            id: '03',
                            habito: 'Consume drogas?',
                            seleccionado: ''
                        },
                    }]
                },
                send_habitos_fisiologicos: {
                    items: [{
                        'alimentacion': {
                            id: '01',
                            habito: 'Realiza las tres comidas diarias?',
                            seleccionado: ''
                        },
                        'diuresis': {
                            id: '02',
                            habito: 'Orina de manera normal?',
                            seleccionado: ''
                        },
                        'sueno': {
                            id: '03',
                            habito: 'Duerme de manera normal?',
                            seleccionado: ''
                        },
                    }]                    
                }

            }
        },                
        props: {
            send_consulta_medica: Object
        },
        computed: {
            ...mapGetters(['fields_historia_clinica']),
        },
        setup() {
            const icons = reactive({
                mdiClose,
                mdiAccount,
                mdiAlarmMultiple,
                mdiAlarmPlus,
                mdiPencil,
                mdiCalendar,
                mdiAccountHeart,
                mdiNeedle,
                mdiRadiologyBoxOutline,
                mdiCached,
                mdiDelete,
                mdiStopCircleOutline,
                mdiContentSave
            });  


            return {
            icons
            }
        },  
        methods: {
            async guardar () {
            const isValid = await this.$refs.datos_basicos.validate()
            if (!isValid) {
                return true
            }
            this.isLoading = true      
            for (const property in this.isDisabled) {
                this.isDisabled[property] = false
            }
                this.$notify({
                group: 'generic',
                type: 'success',
                title: 'Mensaje importante',
                classes: 'w-full text-lg',
                text: 'Información guardada <b>satisfactoriamente</b>!'
                });      
            //this.loading4 = true 
            const formData = new FormData()
            this.fields_historia_clinica.nu_id_paciente.nu_id_paciente = this.send_consulta_medica.paciente.nu_id_paciente.nu_id_paciente
            this.fields_historia_clinica.nu_id_consulta.nu_id_consulta = this.send_consulta_medica.consulta_medica.nu_id_consulta.nu_id_consulta
            this.fields_historia_clinica.tx_antecedentes_personales_habitos_fisiologicos.tx_antecedentes_personales_habitos_fisiologicos = this.send_habitos_fisiologicos
            this.fields_historia_clinica.tx_antecedentes_personales_habitos_toxicos.tx_antecedentes_personales_habitos_toxicos = this.send_habitos_toxicos

            for (const property in this.fields_historia_clinica) {
                console.log('property: ', typeof this.fields_historia_clinica[property],  property)
                if(typeof this.fields_historia_clinica[property] === 'object') {
                if (this.fields_historia_clinica[property]['caracteristicas']['tipo'] === 'JSON') {
                    formData.append(`${property}`, JSON.stringify(this.fields_historia_clinica[property][property]))                  
                } else {
                    formData.append(`${property}`, `${this.fields_historia_clinica[property][property]}`)
                }

                }
            }


            this.$store.dispatch(UPDATE_CONSULTA_MEDICA_ANTECEDENTES_PERSONALES, formData).then(datos => {
                this.fields_historia_clinica.btn_guardar = 'true';
                this.fields_historia_clinica.nu_id_consulta.nu_id_consulta = datos.nu_id_consulta
                    setTimeout(() => {
                        this.isLoading = false
                        this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));
                        this.$notify({
                            group: 'generic',
                            type: 'success',
                            title: 'Mensaje importante',
                            classes: 'w-full text-lg',
                            text: 'Consulta ' + datos.nu_id_consulta + ' Actualizada satisfactoriamente!'
                        });      

                    },3000)  
                
            }, error => {
                this.fields_historia_clinica.btn_guardar = 'false';
                this.$store.commit(SET_ERROR, error.statusText)
            })
            this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

            },               
            async cancelar () {
                this.$notify({
                group: 'error',
                type: 'Info',
                title: 'Mensaje importante',
                classes: 'w-full text-lg',
                text: 'Operación cancelada!'
                });      
            //this.loading4 = true 
                this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

            this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

            },
        },
        components: {
            Habitos,
            Enfermedades
        }


    }
</script>

<style lang="sass" scoped>

</style>