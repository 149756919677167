<template>
    <div>
        <ValidationObserver ref="datos_basicos">
            <validation-provider
            v-slot="{ errors }"
            name="fields_historia_clinica.group_antecedentes_familiares.tx_antecedentes_familiares.tx_antecedentes_familiares"
            :rules=fields_historia_clinica.group_antecedentes_familiares.tx_antecedentes_familiares.caracteristicas.required
            >
                <v-textarea
                    v-model="fields_historia_clinica.group_antecedentes_familiares.tx_antecedentes_familiares.tx_antecedentes_familiares"
                    auto-grow
                    class="input-group--focused text-lg"
                    :error-messages="errors"
                    :label=fields_historia_clinica.group_antecedentes_familiares.tx_antecedentes_familiares.caracteristicas.label
                    :placeholder=fields_historia_clinica.group_antecedentes_familiares.tx_antecedentes_familiares.caracteristicas.placeholder
                ></v-textarea>                      
            </validation-provider>    
                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cancelar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
                <div>

                        <button v-if="isLoading" class="opacity-50 cursor-not-allowed hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                            Procesando...
                        </button>                                                        
                        <button v-else @click="guardar()" class="hidden md:flex w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                            Guardar
                        </button>                                                        

                        <button v-if="isLoading" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">

                            <div class="rounded-full animate-spin ease duration-300 w-5 h-5 border-2 border-white bg-red-100" >

                                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="2s"
                                        type="rotate"
                                        from="0 50 48"
                                        to="360 50 52"
                                        repeatCount="indefinite" />
                                    
                                    </circle>
                                </svg>
                            </div>
                        </button>                                                        
                

                        <button v-else @click="guardar()" class="flex md:hidden w-full  font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiContentSave }}
                                        </v-icon>
                        </button>                          
                </div>
                </div>
            <!-- Fin Boton de Navegacion -->    

        </ValidationObserver>

    </div>
</template>

<script>
import { UPDATE_CONSULTA_MEDICA, CREATE_CONSULTA_MEDICA} from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached,
  mdiStopCircleOutline,
  mdiContentSave
} from '@mdi/js'

    export default {
        name: 'AntecedentesFamiliares',
        data() {
            return {
                isLoading: false
            }
        },        
        setup() {
            const icons = reactive({
                mdiClose,
                mdiAccount,
                mdiAlarmMultiple,
                mdiAlarmPlus,
                mdiPencil,
                mdiCalendar,
                mdiAccountHeart,
                mdiNeedle,
                mdiRadiologyBoxOutline,
                mdiCached,
                mdiDelete,
                mdiStopCircleOutline,
                mdiContentSave
            });  


            return {
            icons
            }
        },  
        computed: {
            ...mapGetters(['fields_historia_clinica']),
        },
        methods: {
            async guardar () {
            const isValid = await this.$refs.datos_basicos.validate()
            if (!isValid) {
                return true
            }
            this.isLoading = true      
            for (const property in this.isDisabled) {
                this.isDisabled[property] = false
            }
                this.$notify({
                group: 'generic',
                type: 'success',
                title: 'Mensaje importante',
                classes: 'w-full text-lg',
                text: 'Información guardada <b>satisfactoriamente</b>!'
                });      
            //this.loading4 = true 
            const formData = new FormData()
            this.fields_consulta_medica.nu_id_paciente.nu_id_paciente = this.send_consulta_medica.paciente.nu_id_paciente
            for (const property in this.fields_consulta_medica) {
                if(typeof this.fields_consulta_medica[property] === 'object') {
                if (this.fields_consulta_medica[property]['caracteristicas']['tipo'] === 'JSON') {
                    formData.append(`${property}`, JSON.stringify(this.fields_consulta_medica[property][property]))                  
                } else {
                    formData.append(`${property}`, `${this.fields_consulta_medica[property][property]}`)
                }

                }
            }


            //for (const property in this.fields_consulta_medica) {
            //    formData.append(`${property}`, `${this.fields_consulta_medica[property]}`)
            // }
            formData.append('oper', this.send_consulta_medica.paciente.operacion)
            //if (this.fields_consulta_medica.isEditing && this.fields_consulta_medica.nu_id_consulta) {
                if (this.send_consulta_medica.operacion === 'EDITAR' || this.send_consulta_medica.operacion === 'RESULTADO') {
                this.$store.dispatch(UPDATE_CONSULTA_MEDICA, formData).then(datos => {
                    this.fields_consulta_medica.btn_guardar = 'true';
                    this.fields_consulta_medica.nu_id_consulta.nu_id_consulta = datos.nu_id_consulta
                        setTimeout(() => {
                            this.isLoading = false
                            this.$emit("guardar_consulta_medica", JSON.stringify(this.fields_consulta_medica));
                            this.$notify({
                                group: 'generic',
                                type: 'success',
                                title: 'Mensaje importante',
                                classes: 'w-full text-lg',
                                text: 'Consulta ' + datos.nu_id_consulta + ' Actualizada satisfactoriamente!'
                            });      

                        },3000)  
                    
                }, error => {
                    this.fields_consulta_medica.btn_guardar = 'false';
                    this.$store.commit(SET_ERROR, error.statusText)
                })
            } else {
                this.$store.dispatch(CREATE_CONSULTA_MEDICA, formData).then(datos => {

                    this.fields_consulta_medica.btn_guardar = 'true';
                    this.fields_consulta_medica.nu_id_consulta.nu_id_consulta = datos.nu_id_consulta
                        setTimeout(() => {
                            this.isLoading = false
                            this.$emit("guardar_consulta_medica", JSON.stringify(this.fields_consulta_medica));
                            this.$notify({
                                group: 'generic',
                                type: 'success',
                                title: 'Mensaje importante',
                                classes: 'w-full text-lg',
                                text: 'Consulta ' + datos.nu_id_consulta + ' Creada satisfactoriamente!'
                            });      

                        },3000)                  
                        


                }, error => {
                    this.fields_consulta_medica.btn_guardar = 'false';
                    this.$store.commit(SET_ERROR, error.statusText)
                })

            }  

            this.fields_consulta_medica.isEditing = !this.fields_consulta_medica.isEditing     

            },
            async cancelar () {
                this.$notify({
                group: 'error',
                type: 'Info',
                title: 'Mensaje importante',
                classes: 'w-full text-lg',
                text: 'Operación cancelada!'
                });      
            //this.loading4 = true 
                this.$emit("guardar_antecedentes_familiares", JSON.stringify(this.fields_historia_clinica));

            this.fields_historia_clinica.group_antecedentes_familiares.isEditing = !this.fields_historia_clinica.group_antecedentes_familiares.isEditing     

            }, 
        }
    }
</script>

<style lang="sass" scoped>

</style>