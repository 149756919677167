
<template>
    <div class="text-skin-base w-full">
                <div class="md:flex  md:justify-start w-full">                  
                    <div class="my-auto p-2  w-full">
                        <button @click="registrar_tratamiento()" class="w-full font-semibold bg-skin-button-accent  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-4 mt-1 m-1">
                            Registrar tratamiento
                        </button>                          
                    </div>
<!--                            @change="verificar_frecuencia()"                        
-->        
                </div>


        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogTratamiento" :value="true" @input="dialogTratamiento = false"                   
            >
            <v-card>
              <v-container>
              <div class="w-full md:w-1/2 mx-auto my-auto">
                <TratamientoRegistrar @guardar_tratamiento="return_tratamiento" :send_tratamiento="send_tratamiento" titulo="Registrar tratamiento"/>
              </div>

              </v-container>

            </v-card>
          </v-dialog>              

  
    </div>
</template>
<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
import TratamientoRegistrar from "@/components/tratamiento/TratamientoRegistrar.vue";

export default {
    name: 'TratamientoOpciones',
    data() {
        return {
            dialogTratamiento: false,
            dialogPaciente: false,
            buscarPaciente: false, 
            send_paciente: {},
            datos_paciente: [],
            isLoading: false,
            json_paciente: ''
        }
    },
    props: {
        send_tratamiento: Object,
        titulo: String

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
        registrar_tratamiento () {
            this.dialogTratamiento = true
            this.send_tratamiento.operacion = 'REGISTRAR'
            // console.log(value) // someValue
        },

        return_tratamiento(msg) {
            //this.buscarPersona('params')
            var guardar_tratamiento = JSON.parse(msg);
            //this.select_paciente = JSON.parse(msg);
            console.log("return_tratamiento guardar_tratamiento: " , guardar_tratamiento);
            console.log("return_tratamiento guardar_tratamiento: " , guardar_tratamiento.btn_guardar);

            
            this.$emit("guardar_tratamiento", guardar_tratamiento);
            this.dialogTratamiento = false
            //this.send_paciente = msg;
        },    

    },
    computed: {
        listaPacientes () {
        return this.datos_paciente.map(entry => {
            console.log("tx_nombres : ", entry.tx_nombre_completo)
            const Description = entry.tx_nombre_completo.length > this.descriptionLimit
            ? entry.tx_nombre_completo.slice(0, this.descriptionLimit) + '...'
            : entry.tx_nombre_completo

            return Object.assign({}, entry, { Description })
        })
        },    
    }, 
    components: {
        TratamientoRegistrar,
        
    },
    mounted() {
        //this.searchPaciente()
    },     
}
</script>